import React from 'react'
import { Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { sortByDateDescending } from '../../../Lead/functions';
import { useNavigate } from 'react-router-dom';
import { useAuthSession } from '../../../../Auth';
import PageviewIcon from '@mui/icons-material/Pageview';

type ViewLeadButtonProps = {
    lead: TeamViewerLead;
};

export default function ViewLeadButton({ lead }: ViewLeadButtonProps) {
  const navigate = useNavigate();
  const { clearProxyLogin } = useAuthSession();

  const onViewLead = () => {
    clearProxyLogin();
    //@ts-ignore
    navigate(`/lead/${lead?.profile.customerID}`);
  };
  return (
    <Button onClick={onViewLead} sx={{gap: 1}}>
      <PageviewIcon />
      View Lead
    </Button>
  );
}
