import formatCurrency from "./formatCurrency";
import parseDate from "./parseDate";
import parseNotes from "./parseNotes";
import formatPhone from "./formatPhone";
import parseMS from "./parseMS";
import formatDateString from "./formatDate";
import countMissedPayments from "./countMissedPayments";

const limitString = (str: string, limit: number) => {
    if(!str) return "";
    return str.length > limit ? str.substring(0, limit) + "..." : str;
};

export {
    formatCurrency,
    parseDate,
    parseNotes,
    formatPhone,
    parseMS,
    formatDateString,
    countMissedPayments,
    limitString,
}