import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { formatPhone, parseMS } from "../../../util";
import Tooltip from '@mui/material/Tooltip';

type ContactMethodProps = {
  phone: string;
  email: string;
  ms_number: string;
  customerId: string;
};

export default function ContactMethods({
  phone,
  email,
  ms_number,
  customerId,
}: ContactMethodProps) {
  const [phoneAnchorEl, setPhoneAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [emailAnchorEl, setEmailAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [phoneCopied, setPhoneCopied] = React.useState(false);
  const [emailCopied, setEmailCopied] = React.useState(false);

  const handlePhoneClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    phone && setPhoneAnchorEl(event.currentTarget);
  };
  const handlePhoneClose = () => {
    setPhoneAnchorEl(null);
    setPhoneCopied(false);
  };
  const handleEmailClick = (event: React.MouseEvent<HTMLButtonElement>) => {
   email && setEmailAnchorEl(event.currentTarget);
  };
  const handleEmailClose = () => {
    setEmailAnchorEl(null);
    setEmailCopied(false);
  };

  const onCopyPhone = async () => {
    await navigator.clipboard.writeText(
      phone || `No Phone available for this lead MS#${parseMS(ms_number)}`,
    );
    setPhoneCopied(true);
  };

  const onCopyEmail = async () => {
    await navigator.clipboard.writeText(
      email.toLowerCase() ||
        `No Email available for this lead MS#${parseMS(ms_number)}`,
    );
    setEmailCopied(true);
  };

  const phoneOpen = Boolean(phoneAnchorEl);
  const phoneId = phoneOpen ? "phone-popover" : undefined;

  const emailOpen = Boolean(emailAnchorEl);
  const emailId = emailOpen ? "email-popover" : undefined;

  return (
    <Wrapper>
      <Tooltip title={!phone ? "No Phone on Customer Account" : "Contact Customer"} arrow>
        <LogoButton onClick={handlePhoneClick} variant="outlined"
        color={!phone ? "error" : "primary"}
        error={!phone}
        >
          <img
            style={{ width: 25, height: 25 }}
            src={require("../assets/phone-icon.png")}
          />
        </LogoButton>
      </Tooltip>
      <Tooltip title={!email ? "No Email on Customer Account" : "Email Customer"} arrow>
        <LogoButton
          onClick={handleEmailClick}
          variant="outlined"
          color={!email ? "error" : "primary"}
          error={!email}
        >
          <img
            style={{ width: 22, height: 16 }}
            src={require("../assets/mail-icon.png")}
          />
        </LogoButton>
      </Tooltip>
      <Tooltip
        title="Netsuite Details"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <a
          href={`https://5521729.app.netsuite.com/app/common/entity/custjob.nl?id=${customerId}`}
          target="blank"
        >
          <LogoButton variant="outlined">
            <img
              style={{ width: 30, height: 30 }}
              src={require("../assets/netsuite.png")}
            />
          </LogoButton>
        </a>
      </Tooltip>
      <Popover
        id={phoneId}
        open={phoneOpen}
        anchorEl={phoneAnchorEl}
        onClose={handlePhoneClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <a
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              textDecoration: "none",
              width: "100%",
              height: "100%",
            }}
            href={`tel:${phone}`}
          >
            <Button sx={{ width: "100%" }}>Call: {formatPhone(phone)}</Button>
          </a>
          <Button onClick={onCopyPhone}>
            {phoneCopied ? "Copied" : "Copy"}
          </Button>
        </div>
      </Popover>
      <Popover
        id={emailId}
        open={emailOpen}
        anchorEl={emailAnchorEl}
        onClose={handleEmailClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <a
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              textDecoration: "none",
              textTransform: "none",
              width: "100%",
              height: "100%",
            }}
            href={`mailto:${email}`}
            target="_blank"
          >
            <Button sx={{ width: "100%" }}>Email: {email}</Button>
          </a>
          <Button onClick={onCopyEmail}>
            {emailCopied ? "Copied" : "Copy"}
          </Button>
        </div>
      </Popover>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const LogoButton = styled(Button)<{ error?: boolean }>`
  background: #fff;
  border-radius: 50%; /* Force circular shape */
  width: 51px; /* Explicit width */
  height: 50px; /* Explicit height */
  padding: 0; /* Remove internal padding */
  min-width: 40px; /* Override MUI default min-width */
  min-height: 40px; /* Enforce height consistency */
  display: flex; /* Flex for centering content */
  justify-content: center;
  align-items: center;
  border: ${({error}) => `1px solid ${error ? "#d32f2f" : "#dddddd"}`};
  box-sizing: border-box; /* Include borders in the size calculation */
`;