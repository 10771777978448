import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";

function stripNonNumeric(str: string) {
  return str.replace(/\D/g, "");
}

const getCustomerInfoByPhone = async (phone: string): Promise<CustomerInfo> => {
  const endpoint = `https://api.momentumsolar.io/collections/lead/phone-number/${stripNonNumeric(phone)}`;

  const res = await axios.get(endpoint);

  return res.data;
};

export const assignLeadByID = async ({internal_id, email}: {internal_id: string; email: string}) => {
    const endpoint = "https://api-dev.momentumsolar.io/collections/lead/confirm";
    const res = await axios.post(endpoint, {internal_id, email});
    return res.data;
};

export const useCustomerInfo = ({ phone }: { phone: string }) => {
  return useQuery({
    queryKey: ["customer-info", phone],
    queryFn: () => getCustomerInfoByPhone(phone),
    enabled: !!phone && stripNonNumeric(phone).length === 10,
  });
};