import styled from "@emotion/styled";

const Title = styled.h1`
  font-size: 1.1em;
  text-align: center;
  color: #707070;
  // background-color: #f0f4f5;
  text-align: left;
  padding: 0.5em;
  font-weight: normal;
  text-transform: capitalize;
  font-family: Gotham-Medium;
`;

export default Title;
