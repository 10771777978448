import dayjs from "dayjs";

const sortByDateDescending = (array: any[], dateKey: string) => {
  if (!array) return [];

  return [...array].sort((a, b) => {
    const dateA = a[dateKey] ? dayjs(a[dateKey]).valueOf() : 0;
    const dateB = b[dateKey] ? dayjs(b[dateKey]).valueOf() : 0;
    
    return dateB - dateA;
  });
};

export { sortByDateDescending };
