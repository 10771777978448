import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import team_members from "../team_members.json";
import { getTeamMembers } from "../api";


const useTeam = () => {
    return useQuery({
      queryKey: ["get-team"],
      queryFn: getTeamMembers,
    });
};

export default useTeam;
