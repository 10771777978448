import styled from "@emotion/styled";
import { COLORS } from '../../../Theme';
import { Typography } from "@mui/material";

export const Content = styled.div`
  display: flex;
  gap: 20px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1em;
    gap: 10px;
    height: 50%;
`;

export const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 15px 70px;
`;

export const HelperText = styled(Typography)`
    color: ${COLORS.PRIMARY};
`;