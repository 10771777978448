import { useState } from "react";
import Button from '@mui/material/Button';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Modal from "../Modal";
import { useParams } from "react-router-dom";
import { formatCurrency } from '../../util';
import Transactions from "./Transactions";
import { COLORS } from "../../Theme";

type OutcomeModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    invoice: Invoice;
};

const getStatusIcon = (status: string) => {
    if(status === "paid in full") {
        return <TaskAltIcon sx={{color: "green"}}/>;
    }
    if (status === "paid") {
        return <TaskAltIcon sx={{color: "green"}}/>;
    } else if (status === "pending") {
        return <PendingOutlinedIcon sx={{color: "orange"}}/>;
    } else if (status === "overdue") {
        return <ErrorOutlineIcon sx={{color: "red"}}/>;
    } else if (status === "open") {
        return <PendingOutlinedIcon sx={{color: "orange"}}/>;
    }
    else {
        return <ErrorOutlineIcon sx={{color: "red"}}/>;
    } 
};

const getInvoiceStatusText = (status: string, payment_date: string) => {
    if(status === "paid in full") {
        return `Invoice Paid in Full.  Last transaction on ${payment_date}`;
    }
    if (status === "paid") {
        return `Invoice Paid on ${payment_date}`;
    } else if (status === "pending") {
        return `Invoice Pending`;
    } else if (status === "overdue") {
        return `Invoice Overdue`;
    } else if (status === "open") {
        return `Invoice Open`;
    } 
    else {
        return `Invoice Status Unknown`;
    }
};

export default function InvoiceModal({open, setOpen, invoice}: OutcomeModalProps) { 
  const [view, setView] = useState("summary");
  const { id: customer_id } = useParams();
  const navigate = useNavigate();
    
  const onViewInvoice = () => {
    navigate(
      `/invoice/customer-id/${customer_id}/internalid/${invoice.internalid}`,
    );

    setOpen(false);
  };  

  return (
    <Modal
      setOpen={setOpen}
      open={open}
      onClose={() => setOpen(false)}
      width={view === "transactions" ? "600px" : "475px"}
    >
      <ModalContent>
        <TitleContainer>
          <Title>Invoice #{invoice.internalid}</Title>
          <Status className={invoice.status.toLowerCase()}>
            {invoice.status}
          </Status>
        </TitleContainer>
        <StatusBar className={invoice.status.toLowerCase()}>
          <div style={{ paddingLeft: 20, paddingTop: 2 }}>
            {getStatusIcon(invoice.status.toLowerCase())}
          </div>
          <StatusBarText>
            {getInvoiceStatusText(
              invoice.status.toLowerCase(),
              invoice.payments[0]?.trandate,
            )}
          </StatusBarText>
        </StatusBar>
        {view === "transactions" ? (
          <Transactions invoice={invoice} />
        ) : (
          <ContentContainer>
            <InfoContainer>
              <InfoItem>
                <Label>Issued on</Label>
                <Value>{invoice.date || "NA"}</Value>
              </InfoItem>
              <InfoItem>
                <Label>Due on</Label>
                <Value>{invoice.due_date || "NA"}</Value>
              </InfoItem>
              <InfoItem>
                <Label>Loan Type</Label>
                <Value>-</Value>
              </InfoItem>
              <InfoItem>
                <Label>Invoice Type</Label>
                <Value>{invoice.invoice_type || "NA"}</Value>
              </InfoItem>
            </InfoContainer>
            <Seperator />
            <TableContainer>
              <TableTitle>Invoice Details</TableTitle>
              {/* <Label>{invoice.memo}</Label> */}
              <Table>
                <TableHeader>
                  <TableCell style={{ width: "60rem" }}>
                    <TableCellText>Description</TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>Amount</TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>Remaining</TableCellText>
                  </TableCell>
                </TableHeader>
                <TableRow style={{height: 55}}>
                  <TableCell style={{ width: "60rem" }}>
                    <TableCellText>{invoice.memo || "No Description"}</TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {formatCurrency(invoice.amount)}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {formatCurrency(invoice.amountremaining)}
                    </TableCellText>
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "60rem" }}>
                    <TableCellTotalText>Total</TableCellTotalText>
                  </TableCell>
                  <TableCell style={{ paddingLeft: 100 }}>
                    <TableCellTotalText>
                      {formatCurrency(invoice.amount)}
                    </TableCellTotalText>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </ContentContainer>
        )}
        <Seperator2 />
        <ButtonsContainer>
          <Button
            style={{ width: 200 }}
            variant="outlined"
            onClick={() =>
              setView((prev) =>
                prev === "summary" ? "transactions" : "summary",
              )
            }
          >
            {view === "summary" ? "Transactions" : "Summary"}
          </Button>
          {/* <Button
            style={{ width: 200 }}
            variant="outlined"
            onClick={onViewInvoice}
          >
            View Invoice
          </Button> */}
        </ButtonsContainer>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Adjust this value to make the modal wider */
  height: 600px;
  align-items: center;
  justify-content: flex-start;
`;

const StatusBar = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30px;
    margin: 0;
    &.paid {
        background-color: #DFF2DF;
        border-bottom: 1px solid green;
    }
    &.overdue {
        background-color: #F2DFDF;
        border-bottom: 1px solid red;
    }
    &.pending {
        background-color: #F2EFEF;
        border-bottom: 1px solid orange;
    }
    &.open {
        background-color: #F2EFEF;
        border-bottom: 1px solid orange;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    width: 100%;
`;

const StatusBarText = styled.p`
    font-size: 0.7em;
    color: charcoal;
    margin: 0;
    padding: 0;
    text-align: left;
    padding-left: 20px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-bottom: 1rem;
`;

const Title = styled.h1`
    font-size: 1.3em;
    text-align: center;
    color: #354669;
    text-align: left;
    padding: 0.5em;
    font-weight: normal;
`;

const Status = styled.p`
  font-size: 0.7em;
  color: #354669;
  border-radius: 50px;
  padding: 0.5em 1em;
  text-transform: capitalize;
  margin-right: 20px;
  &.overdue {
    background-color: #f8d7da;
    color: #721c24;
  }
  &.paid {
    background-color: #d4edda;
    color: #155724;
  }
  &.pending {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
  &.open {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
`;

const InfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    width: 90%;
    height: 75px;
    padding: 0rem 5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // border: 1px solid #EDEDED;
    height: 40px;
`;

const Label = styled.p`
    font-size: 0.7em;
    color: #354669;
    margin: 0;
    padding: 0;
    text-align: left;
`;

const Value: any = styled.p`
    font-size: 0.9em;
    color: #354669;
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: capitalize;
`;

const Seperator = styled.div`
    width: 90%;
    height: 1px;
    background-color: lightgrey;
    margin: 1rem 0rem;
`;

const Seperator2 = styled.div`
    width: 100%;
    height: 1px;
    background-color: lightgrey;
    margin-top: 3rem;
    margin-bottom: 2rem;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
    border: 1px solid lightgrey;
    border-radius: 0.2rem;
    padding: 0.5rem;
    margin-top: 1rem;
`;

const TableTitle = styled.h1`
    font-size: 1em;
    text-align: center;
    color: #354669;
    text-align: left;
    font-weight: normal;
    margin: 0;
    margin-top: 1rem;
`;

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    width: 100%;
    height: 40px;
    background-color: #F0F4F5;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid lightgrey;
`;

const TableCell = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

const TableCellText = styled.p`
    font-size: 0.8em;
    color: #354669;
    margin: 0;
    padding: 0;
    padding-left: 20px;
`;

const TableCellTotalText = styled.p`
    font-size: 0.9em;
    color: #354669;
    margin: 0;
    padding: 0;
    font-weight: bold;
    padding-left: 20px;
`;

const TableCellBoldText = styled.p`
    font-size: 0.8em;
    color: #354669;
    margin: 0;
    padding: 0;
    font-weight: bold;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 425px;
`;