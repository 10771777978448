import { useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams, GridColumnHeaderParams } from '@mui/x-data-grid';
import { limitString } from "../../../../util";
import styled from "@emotion/styled";
import { GridColumnHeader, GridCellText } from "../../../Lead/components/styled-components"
import { sortByDateDescending } from "../../../Lead/functions";
import { Loader } from "../../../../GlobalComponents";
import ViewLeadButton from "./ViewLeadButton";
import { Typography } from "@mui/material";

type LeadsTableProps = {
  leads: TeamViewerLead[] | undefined;
  isLoading: boolean;
};

const columns: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Date of Outcome",
    width: 180,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => {
      const { isCurrent } = params.row;
      const sortedActivity = sortByDateDescending(
        //@ts-ignore
        params.row.collection_activity,
        "timestamp",
      );
      console.log("params", params);
      if(isCurrent) {
        return (
          <LiveWrapper>
            <LiveCircle />
            <LiveText>Current Lead</LiveText>
          </LiveWrapper>
        )
      }
      return <GridCellText>{sortedActivity[0]?.timestamp}</GridCellText>;
    },
    renderHeader: () => <GridColumnHeader>Date of Outcome</GridColumnHeader>,
  },
  {
    field: "customer_name",
    headerName: "Customer Name",
    width: 170,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => {
      return <GridCellText style={{textTransform: "capitalize"}}>{params.row.profile.name.toLowerCase()}</GridCellText>;
    },
    renderHeader: () => <GridColumnHeader>Customer Name</GridColumnHeader>,
  },
  {
    field: "outcome",
    headerName: "Outcome",
    width: 240,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => {
      const { isCurrent } = params.row;
      const sortedActivity = sortByDateDescending(
        //@ts-ignore
        params.row.collection_activity,
        "timestamp",
      );
      console.log("params", params);
      if(isCurrent) {
        return null;
      }
      return <GridCellText>{sortedActivity[0]?.collection_outcomes} - {sortedActivity[0]?.collection_suboutcomes}</GridCellText>;
    },
    renderHeader: () => <GridColumnHeader style={{paddingLeft: 20}}>Outcome</GridColumnHeader>,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 180,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => {
      return <ViewLeadButton lead={params.row} />;
    },
    renderHeader: () => <GridColumnHeader style={{paddingLeft: 20}}>Actions</GridColumnHeader>,
  },
];

export default function LeadsTable({ leads, isLoading }: LeadsTableProps) {

  if(isLoading) {
    return (
      <div style={{ paddingTop: 22 }}>
        <Loader text="Loading Leads..." />
      </div>
    );
  }

  if (!leads?.length)
    return <NoteText>No lead history found</NoteText>;

  return (
    <div style={{ height: "80%", width: "88%", paddingBottom: 35 }}>
      <DataGrid
        rows={leads || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
        getRowId={(row) => row.profile.customerID}
        sx={{
          width: "111%",
          borderRadius: 4,
          padding: 1,
          paddingLeft: 3,
          border: "1px solid #DDDDDD",
          "& .MuiDataGrid-row": { cursor: "pointer" },
        }}
      />
    </div>
  );
}

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
`;

const LiveWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LiveCircle = styled.div`
  background-color: #2adb7d;
  border-radius: 50px;
  width: 10px;
  height: 10px;
`;

const LiveText = styled(Typography)`
  color: #2adb7d;
`;

