import React from "react";
import PersonIcon from "../PersonIcon";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";

type PersonChipProps = {
  person: TeamMember;
  onClick: (person: TeamMember) => void;
};

export default function PersonChip({ person, onClick }: PersonChipProps) {
  return (
    <Tooltip title={person.email} arrow>
      <Container onClick={() => onClick(person)}>
        <div style={{width: 30, marginRight: 5}}>
          <PersonIcon person={person} size="small"/>
        </div>
        <Text>{person.email}</Text>
        <CloseIcon sx={{color: "grey", width: 20, height: 20}}/>
      </Container>
    </Tooltip>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  border-radius: 1.5em;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  width: 150px;
  height: 25px;
`;

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap; /* Ensures the text stays on one line */
`;
