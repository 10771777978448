import React from "react";
import { PageView } from "../../GlobalComponents";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import LeadHistory from "./LeadHistory";
import { useNavigate } from "react-router-dom";
import DevToolsModal from "../Lead/dev-tools";
import { devEmails } from "../../config";
import { useAuthSession } from "../../Auth";
import LookupCustomerModal from "./LookupCustomerModal";
import SearchIcon from "@mui/icons-material/Search";
import StartIcon from "@mui/icons-material/Start";

export default function Dashboard() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [devToolsOpen, setDevToolsOpen] = React.useState(false);
  const [lookupModalOpen, setLookupModalOpen] = React.useState(false);
  const { email } = useAuthSession();

  return (
    <PageView
      headerProps={{
        devToolsOpen: devToolsOpen,
        setDevToolsOpen: setDevToolsOpen,
      }}
    >
      <Container>
        <Button
          variant="outlined"
          onClick={() => navigate("/lead")}
          sx={{ width: 220 }}
          endIcon={<StartIcon />}
        >
          Start New Lead
        </Button>
        {/* <Button
          variant="outlined"
          onClick={() => setLookupModalOpen(true)}
          sx={{ width: 220 }}
          startIcon={<SearchIcon />}
        >
          Look Up Customer
        </Button> */}
        {/* <Seperator /> */}
        {/* <LeadHistory /> */}
      </Container>
      <DevToolsModal
        open={devToolsOpen}
        setOpen={setDevToolsOpen}
        methods={{}}
      />
      <LookupCustomerModal
        open={lookupModalOpen}
        setOpen={setLookupModalOpen}
      />
    </PageView>
  );
}

const Container = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: white;
  gap: 20px;
`;

const Seperator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ededed;
  margin: 3em 0;
`;
