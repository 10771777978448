import styled from "@emotion/styled";
import React from "react";
import SideBar from "../sidebar";
import { useLocation } from "react-router-dom";
import Header, { HeaderProps } from "../header2.0";

// This component handles page layout and styling

type PageViewProps = {
  children?: React.ReactNode;
  headerHeight?: number;
  headerProps?: HeaderProps;
};

function PageView({ children, ...props }: PageViewProps) {
  const { pathname } = useLocation();
  return (
    <FullPage key={"page"}>
      {/* <SideBar {...props.headerProps} /> */}
      <div style={{ padding: "50px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: 40,
          }}
        >
          <Wrapper>
            {/* <Header {...props.headerProps}/> */}
            <Header {...props.headerProps} />
            <ContentWrapper {...props} key={pathname}>
              {children}
            </ContentWrapper>
          </Wrapper>
        </div>
      </div>
    </FullPage>
  );
}

// box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */

export default PageView;

const FullPage = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #EFEFEF;
  display: flex;
`;

const Wrapper = styled.div`
  border-radius: 40px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  // border-radius: 40px;
  width: 100%;
  height: 95%;
  padding-bottom: 12em;
  overflow-y: auto;
  overflow-x: auto;
`;
