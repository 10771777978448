import React from "react";
import styled from "@emotion/styled";
import Modal, { ModalProps as MuiModalProps } from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

type TagsProps = {
  title: string;
  text?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  headerColor?: string;
  headerTextColor?: string;
  children?: React.ReactElement;
};

export default function StyledModal({
  title,
  text = "",
  open,
  setOpen,
  headerColor = "#F0F4F5",
  headerTextColor = "#354669",
  children,
}: TagsProps) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <ModalContent>
        <ModalHeader backgroundColor={headerColor} textColor={headerTextColor}>
          <TagDot style={{ marginLeft: 20 }} />
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        {children ?? (
          <ModalBody>
            <div style={{padding: 10}}>
              <ModalText>{text}</ModalText>
            </div>
          </ModalBody>
        )}
        <XButton onClick={() => setOpen(false)}>
          <CloseOutlinedIcon />
        </XButton>
      </ModalContent>
    </Modal>
  );
}

const TagDot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #354669;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 425px;
  height: 200px;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  position: relative;
  background-color: #fff;
`;

const ModalHeader = styled.div<{ backgroundColor: string; textColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  height: 50px;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const ModalTitle = styled.h1`
  font-size: 0.9rem;
  color: #354669;
  margin: 0;
  text-transform: capitalize;
  font-weight: normal;
  width: 100%;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  height: 70%;
`;

const ModalText = styled.p`
  font-size: 0.9rem;
  color: #354669;
  margin: 0;
  text-transform: capitalize;
  font-weight: normal;
  width: 100%;
  text-align: center;
`;

const XButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }
`;
