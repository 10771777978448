import { useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams, GridColumnHeaderParams } from '@mui/x-data-grid';
import { Status } from "../../../GlobalComponents";
import { formatCurrency, limitString } from "../../../util";
import styled from "@emotion/styled";
import { Title } from "../components";
import CollectionsEventModal from "./CollectionEventModal";
import { GridColumnHeader, GridCellText } from "../components/styled-components";
import { sortByDateDescending } from "../functions";

const columns: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Date of Outcome",
    width: 180,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Date of Outcome</GridColumnHeader>
    ),
  },
  {
    field: "email",
    headerName: "Agent",
    width: 200,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{limitString(params.value, 20)}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Agent</GridColumnHeader>
    ),
  },
  {
    field: "collection_outcomes",
    headerName: "Outcome",
    width: 200,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <div style={{paddingLeft: 20}}>
        <Status status={params.value} textStyle={{ fontSize: 12 }} />
      </div>
    ),
    renderHeader: () => (
      <div style={{paddingLeft: 30}}>
        <GridColumnHeader>Outcome</GridColumnHeader>
      </div>
    ),
  },
  {
    field: "collection_suboutcomes",
    headerName: "Sub Outcome",
    width: 140,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Sub Outcome</GridColumnHeader>
    ),
  },
  {
    field: "contact_method",
    headerName: "Contact Method",
    width: 140,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Contact Method</GridColumnHeader>
    ),
  },
  {
    field: "ms_time_to_call",
    headerName: "Time to Call",
    width: 140,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Time to Call</GridColumnHeader>
    ),
  },
  {
    field: "ms_collections_amt_add_pay",
    headerName: "Amount Collected",
    width: 200,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{formatCurrency(params.value)}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Amount Collected</GridColumnHeader>
    ),
  },
  {
    field: "promise_to_pay_date",
    headerName: "Promise Date",
    width: 160,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Promise Date</GridColumnHeader>
    ),
    // @ts-ignore
    // renderCell: (params: GridValueGetterParams) => (
    //   <Status status={params.value} />
    // ),
  },
  {
    field: "ms_collections_reas_miss_pay",
    headerName: "Reason for Missed Payment",
    width: 220,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Reason for Missed Payment</GridColumnHeader>
    ),
  },
  {
    field: "collection_notes",
    headerName: "Notes",
    width: 400,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => (
      <GridColumnHeader>Notes</GridColumnHeader>
    ),
  },
];

type CollectionsHistoryProps = {
    collection_activity: CollectionEvent[];
    onClick?: (collection: CollectionEvent) => void;
};

export default function CollectionsHistory({collection_activity, onClick}:CollectionsHistoryProps) {
  const [selectedEvent, setSelectedEvent] = useState<CollectionEvent | null>(null);
  const onRowClick = (params: any, event: any) => {
    // const collection = collections_history.find((val: any) => val.id === params.row.id);
    // //@ts-ignore
    // onClick(collection as CollectionEvent);
    setSelectedEvent(params.row as CollectionEvent);
  };

  if(collection_activity?.length === 0) return <NoteText>No collections history found</NoteText>;

  return (
    <div style={{ height: 400, width: '80vw', paddingBottom: 35 }}>
      <Title>Collections History</Title>
      <DataGrid
        rows={sortByDateDescending(collection_activity, "timestamp")}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
        onRowClick={onRowClick}
        getRowId={(row) => row.id}
        sx={{ width: "111%", borderRadius: 4, padding: 1, paddingLeft: 3, border: "1px solid #DDDDDD", '& .MuiDataGrid-row': { cursor: 'pointer' } }}
      />
      <CollectionsEventModal collection_event={selectedEvent} open={!!selectedEvent} setOpen={() => setSelectedEvent(null)}/>
    </div>
  );
}

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
`;