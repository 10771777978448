import React, { useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
//@ts-ignore
import { ReactComponent as MSLogo } from './assets/ms-logo.svg';
//@ts-ignore
import { ReactComponent as AdminIcon } from './assets/admin-icon.svg';
//@ts-ignore
import { ReactComponent as AdminIconBlue } from './assets/admin-icon-blue.svg';
//@ts-ignore
import { ReactComponent as HomeIcon } from './assets/home-icon.svg';
//@ts-ignore
import { ReactComponent as HomeIconBlue } from './assets/home-icon-blue.svg';
//@ts-ignore
import { ReactComponent as LogoutIcon } from './assets/logout-icon.svg';
import ConstructionIcon from '@mui/icons-material/Construction';
import Button from '@mui/material/Button';
import NavButton from './NavButton';
import { useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { adminEmails } from "../../config";
import { useAuthSession } from "../../Auth";
import { devEmails } from "../../config";

type MenuProps = {
  devToolsOpen?: boolean;
  setDevToolsOpen?: (v: boolean) => void;
};

export default function Menu({ devToolsOpen, setDevToolsOpen }: MenuProps) {
  const { instance } = useMsal();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { email, isDev, isProxy } = useAuthSession();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Wrapper open={open}>
      <LogoButton onClick={handleClick}>
        <MSLogo />
      </LogoButton>
      <ExpandableWrapper open={open} height={!!setDevToolsOpen ? 300 : 250}>
        <NavButton
          route="/"
          text="Home"
          icon={pathname === "/" ? <HomeIconBlue /> : <HomeIcon />}
          active={pathname === "/"}
        />
        {adminEmails.includes(email) && (
          <NavButton
            route="/admin"
            text="Admin"
            icon={pathname === "/admin" ? <AdminIconBlue /> : <AdminIcon />}
            active={pathname === "/admin"}
          />
        )}
        {(isDev) && setDevToolsOpen && (
          <NavButton
            onClick={() => setDevToolsOpen(true)}
            text="Dev"
            icon={<ConstructionIcon style={{ width: 35, height: 35 }} />}
          />
        )}
        <NavButton
          onClick={() => instance.logout()}
          text="Logout"
          icon={<LogoutIcon />}
        />
      </ExpandableWrapper>
    </Wrapper>
  );
}

const expandDown = (height: string | number) => keyframes`
  from {
    height: 0;
  }
  to {
    height: ${height}px;
  }
`;

const collapseUp = (height: string | number) => keyframes`
  from {
    height: ${height}px;
  }
  to {
    height: 0;
  }
`;

const Wrapper = styled.div<{ open: boolean }>`
  width: 66px;
  border-radius: ${({ open }) => (open ? '32px' : '50%')};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: fixed;
  top: 62px; /* Adjust as needed for your UI */
  left: 80px; /* Adjust as needed for your UI */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: border-radius 0.3s ease; /* Smooth border-radius transition */
`;

const LogoButton = styled(Button)`
  background: #fff;
  border-radius: 50%;
  width: 66px;
  height: 66px;
  z-index: 1;
`;

const ExpandableWrapper = styled.div<{ open: boolean, height: string | number }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  animation: ${({ open, height }) => (open ? expandDown(height) : collapseUp(height))} 0.3s ease forwards;
  height: ${({ open }) => (open ? '300px' : '0')};
  transform-origin: top;
  ${({ open }) => (!open && 'animation: none;')};
`;
