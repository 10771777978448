import React from 'react';
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

type NavButtonProps = {
    route?: string;
    onClick?: () => void;
    icon: React.ReactElement;
    text?: string;
    active?: boolean;
};

export default function NavButton({
  route,
  onClick,
  icon,
  text,
  active,
}: NavButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        route && navigate(route);
        onClick && onClick();
      }}
      sx={{
        fontFamily: "Gotham-Medium",
        fontSize: 11,
        color: active ? "#33CCFF" : "#DDDDDD",
      }}
    >
      <Wrapper>
        {icon}
        <ButtonText>{text || ""}</ButtonText>
      </Wrapper>
    </Button>
  );
}

const Wrapper = styled.div`

`;

const ButtonText = styled.div`
  font-family: "Gotham-Medium;
  font-size: 11px;
`;
