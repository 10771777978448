import { useEffect, useState, useMemo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getLead, getOutcomeOptions, notifyManager } from "./api";
import { PageView, Status, Tabs } from "../../GlobalComponents";
import styled from "@emotion/styled";
import InvoicesList from "./InvoicesList";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import OutcomeModal from "./OutcomeModal";
import { InvoiceModal, Loader } from "../../GlobalComponents";
import LeadInfoTable from "./LeadInfoTable";
import PaymentTransactions from "./PaymentTransactions";
import Notes from "./Notes";
import { useParams, Link } from "react-router-dom";
import { parseNotes } from "../../util";
import { useMsal } from "@azure/msal-react";
import DevToolsModal from "./dev-tools";
import LoadingModal from "./LoadingModal";
import CollectionsHistory from "./CollectionsHistory";
import { devEmails } from "../../config";
import { useAuthSession } from "../../Auth";
import Cases from "./Cases";
import LeadInfoBanner from "./components/LeadInfoBanner";
import { formatCurrency, formatDateString, countMissedPayments } from "../../util";
import ContactMethods from "./ContactMethods";
import { Typography } from "@mui/material";
import { adminEmails } from "../../config";
import { sortByDateDescending } from "./functions";
import InboxIcon from '@mui/icons-material/Inbox';
import { COLORS } from "../../Theme";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QueueEmpty from "./QueueEmpty";

const tabs = [
  "invoices",
  "payment transactions",
  "collections history",
  "notes",
];

export default function Lead() {
  const { id: internal_id } = useParams();
  const { instance } = useMsal();
  const loginEmail = instance.getActiveAccount()?.username;
  const [tab, setTab] = useState(tabs[0]);
  const [outcomeModalOpen, setOutcomeModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [devToolsOpen, setDevToolsOpen] = useState(false);
  const navigate = useNavigate();

  const { email } = useAuthSession();

  const {
    data: actualLead,
    isLoading: leadLoading,
    isFetching: leadFetching,
    error,
    refetch,
  } = useQuery({
    queryKey: ["lead", email, internal_id],
    queryFn: () =>
      getLead(email, internal_id, adminEmails.includes(loginEmail as string)),
  });

  const {
    data: outcomeData,
    isLoading: outcomeLoading,
    error: outcomeError,
  } = useQuery({ queryKey: ["outcomeData"], queryFn: getOutcomeOptions });

  const { mutate: notifyManagerMutate, isPending: notifyManagerPending, isSuccess: notifyManagerSuccess } =
    useMutation({
      mutationFn: notifyManager,
    });

  const getOutcomesFailure = outcomeData?.status === "failure";
  const outcomesReady = !!outcomeData && !outcomeLoading && !getOutcomesFailure;

  const actualInvoices = actualLead?.invoices;

  const onSelectInvoice = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
    setInvoiceModalOpen(true);
  };

  const notes = useMemo(() => {
    const collectionsActivityNotes = actualLead?.collection_activity
      ? sortByDateDescending(actualLead?.collection_activity, "timestamp")?.map(
          (c) => {
            return {
              date: new Date(c.timestamp),
              description: c.collection_notes,
              agent: c.email,
            };
          },
        )
      : [];
    return [
      ...collectionsActivityNotes,
      ...parseNotes(actualLead?.collections_information?.notes_collection),
    ];
  }, [actualLead]);

  const onNotifyManager = () => {
    notifyManagerMutate({email})
  };

  const renderTabContent = () => {
    if (!actualLead) return <div>ERROR</div>;
    switch (tab) {
      case "invoices":
        return (
          <InvoicesList
            // invoices={actualInvoices as Invoice[]}
            invoices={actualInvoices as Invoice[]}
            oppnum={actualLead?.profile["ms_#"]}
            onClick={onSelectInvoice}
          />
        );
      case "payment transactions":
        return (
          <PaymentTransactions transactions={actualLead?.payments ?? []} />
        );
      case "notes":
        return <Notes notes={notes} />;
      case "loan details":
        // return <LoanDetails loan={lead.loan}/>
        return <div>Loan Details</div>;
      case "collections history":
        return (
          <CollectionsHistory
            collection_activity={actualLead.collection_activity}
          />
        );
      default:
        return <div>ERROR</div>;
    }
  };

  if (leadLoading || leadFetching)
    return (
      <PageView
        headerProps={{
          devToolsOpen: devToolsOpen,
          setDevToolsOpen: setDevToolsOpen,
        }}
      >
        <LoadingModal open={leadLoading || leadFetching} />
        <DevToolsModal
          open={devToolsOpen}
          setOpen={setDevToolsOpen}
          methods={{}}
        />
      </PageView>
    );

  if (error)
    return (
      <PageView
        headerProps={{
          devToolsOpen: devToolsOpen,
          setDevToolsOpen: setDevToolsOpen,
        }}
      >
        <ErrorWrapper>
          <Typography className="error-text">
            Error fetching lead: {error.message}
          </Typography>
          <Button variant="outlined" onClick={() => refetch()}>
            Try again
          </Button>
        </ErrorWrapper>
        <DevToolsModal
          open={devToolsOpen}
          setOpen={setDevToolsOpen}
          methods={{}}
        />
      </PageView>
    );

  if (!actualLead)
    return (
      <PageView
        headerProps={{
          devToolsOpen: devToolsOpen,
          setDevToolsOpen: setDevToolsOpen,
        }}
      >
        <QueueEmpty onRetry={refetch} />
        <DevToolsModal
          open={devToolsOpen}
          setOpen={setDevToolsOpen}
          methods={{}}
        />
      </PageView>
    );

  return (
    <PageView
      headerProps={{
        rightContent: (
          <LeadInfoBanner
            missed_payments={countMissedPayments(actualLead?.invoices)}
            days_overdue={actualLead?.account_data?.daysoverdue || 0}
            amount_overdue={actualLead?.account_data?.overduebalance || 0}
          />
        ),
        devToolsOpen: devToolsOpen,
        setDevToolsOpen: setDevToolsOpen,
      }}
    >
      <Container>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tabs tabs={tabs} setTab={setTab} tab={tab} />
          <div>
            {getOutcomesFailure && (
              <ErrorText>
                There was a problem getting outcome options for this lead.
                Please contact support.
              </ErrorText>
            )}
          </div>
        </div>
        <LeadsHeader>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TitleContainer>
              <Label>Lead Details:</Label>
              <Title>{actualLead.profile?.name}</Title>
            </TitleContainer>
            <ContactMethods
              phone={actualLead?.profile?.phone}
              email={actualLead?.profile?.email}
              ms_number={actualLead.profile["ms_#"]}
              customerId={actualLead?.profile.customerID}
            />
          </div>
          <ActionsContainer>
            <Button
              component={Link}
              target="_blank"
              sx={{
                background: "#ACD89F",
                border: "none",
                color: "#fff",
                fontFamily: "Gotham-Medium",
                fontSize: 12,
                width: 170,
                paddingTop: 1,
                height: 30,
              }}
              to={`https://5521729.app.netsuite.com/app/accounting/transactions/custpymt.nl?entity=${actualLead.profile.customerID}&entityid=${actualLead.profile.customerID}&whence=`}
            >
              <ButtonText>Collect Payment</ButtonText>
            </Button>
            <Tooltip
              title={
                !outcomesReady
                  ? "There was a problem getting outcome options"
                  : "Submit Outcome to close this Lead"
              }
            >
              <Button
                disabled={!outcomesReady}
                onClick={() => setOutcomeModalOpen(true)}
                sx={{
                  background: "#FFC37E",
                  border: "none",
                  color: "#fff",
                  fontFamily: "Gotham-Medium",
                  fontSize: 12,
                  width: 170,
                  paddingTop: 0.9,
                  height: 30,
                }}
              >
                <ButtonText>Select Outcome</ButtonText>
              </Button>
            </Tooltip>
          </ActionsContainer>
        </LeadsHeader>
        <div style={{ display: "flex" }}>
          <Status
            status={
              actualLead.collections_information.ms_customer_status ||
              ("No Status" as InvoiceStatus)
            }
          />
          <Cases cases={actualLead.cases} />
        </div>
        <LeadInfoTable lead={actualLead} />
        <Seperator />
        {renderTabContent()}
      </Container>
      {outcomesReady && (
        <OutcomeModal
          open={outcomeModalOpen}
          setOpen={setOutcomeModalOpen}
          outcomeData={outcomeData}
          internalId={actualLead.profile.customerID}
        />
      )}
      {!!selectedInvoice && (
        <InvoiceModal
          open={invoiceModalOpen}
          setOpen={setInvoiceModalOpen}
          invoice={selectedInvoice as Invoice}
        />
      )}
      <DevToolsModal
        open={devToolsOpen}
        setOpen={setDevToolsOpen}
        lead={actualLead}
        methods={{
          onCopyCustomerId: () =>
            navigator.clipboard.writeText(actualLead.profile["customerID"]),
        }}
      />
    </PageView>
  );
}

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  gap: 15px;
  background: #D6F5FF;

  .error-text {
    color: red;
  }

  .helper-text {
  }
`;

const Container = styled.div`
  padding: 2em;
  background-color: white;
  border-radius: 0.3em;
  padding-bottom: 6em;
  max-width: 100%;
`;

const LeadsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 350px;
`;

const Title = styled(Typography)`
  font-size: 1.3em;
  color: #336699;
  text-transform: capitalize;
  font-family: Gotham-Medium;
  padding: 0;
  margin: 0;
`;

const Label = styled.p`
  font-family: Gotham-Medium;
  color: #707070;
  margin: 0;
`;

const Seperator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ededed;
  margin-top: 2em;
  margin-bottom: 1em;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const ErrorText = styled.div`
  color: red;
`;

const ButtonText = styled.p`
  color: #fff;
  font-family: Gotham-Medium;
  font-size: 14px;
  text-transform: capitalize;
`;
