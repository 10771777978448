import { useState } from "react";
import Button from '@mui/material/Button';
import styled from "@emotion/styled";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Modal from "../../../GlobalComponents/Modal";
import { useParams } from "react-router-dom";
import { formatCurrency } from '../../../util';
import { COLORS } from "../../../Theme";

function toKebabCase(str: string) {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2")
  .replace(/[\s_]+/g, '-')
  .toLowerCase();
}

const limitString = (str: string, limit: number) => {
    if(!str) return "";
    return str.length > limit ? str.substring(0, limit) + "..." : str;
};

// id: string;
//   date_outcomed: string;
//   contact_method: string;
//   time_to_call: string;
//   notes: string;
//   outcome: string;
//   ms_collections_amt_add_pay: string;
//   promise_to_pay_date: string;
//   reason_for_missed_payment: string;

type OutcomeModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    collection_event: CollectionEvent | null;
};

const getStatusIcon = (outcome: string, subOutcome: string) => {
    if (subOutcome === "Payment") {
        return <AttachMoneyIcon sx={{color: "green"}}/>;
    } else if (subOutcome === "Promise To Pay") {
        return <PendingOutlinedIcon sx={{color: "orange"}}/>;
    } else if (outcome === "No Contact") {
        return <PendingOutlinedIcon sx={{color: "orange"}}/>;
    }
    else {
        return <ErrorOutlineIcon sx={{color: "red"}}/>;
    } 
};

const getCollectionsStatusText = (collection_event: CollectionEvent) => {
    const { collection_outcomes: outcome, collection_suboutcomes: subOutcome } = collection_event;
    if(subOutcome === "Payment" && collection_event.ms_collections_amt_add_pay === "Collected Full Balance") {
        return `Collected Full Balance on ${collection_event.date}`;
    }
    if(subOutcome === "Payment" && collection_event.ms_collections_amt_add_pay === "Collected Partial Balance") {
        return `Collected Partial Balance on ${collection_event.date}`;
    }
    if(subOutcome === "Promise To Pay" && collection_event.promise_to_pay_date) {
        return `Promise to Pay on ${collection_event.promise_to_pay_date}`;
    }
    if(outcome === "No Contact") {
        return `No Contact Made - ${collection_event.collection_suboutcomes} on ${collection_event.date}`;
    }
    else {
        return `${outcome} - ${subOutcome}`;
    }
};

export default function CollectionsEventModal({open, setOpen, collection_event}: OutcomeModalProps) { 
  if(!collection_event) return null;

  return (
    <Modal
      setOpen={setOpen}
      open={open}
      onClose={() => setOpen(false)}
      width="475px"
    >
      <ModalContent>
        <TitleContainer>
          <Title>Outcome Date: {collection_event.date}</Title>
          <Status className={toKebabCase(collection_event.collection_outcomes)}>
            {collection_event.collection_outcomes}
          </Status>
        </TitleContainer>
        <StatusBar className={toKebabCase(collection_event.collection_suboutcomes)}>
          <div style={{ paddingLeft: 20, paddingTop: 2 }}>
            {getStatusIcon(collection_event.collection_outcomes, collection_event.collection_suboutcomes)}
          </div>
          <StatusBarText>
            {getCollectionsStatusText(collection_event)}
          </StatusBarText>
        </StatusBar>
        <ContentContainer>
          <InfoContainer>
            <InfoItem>
              <Label>Agent</Label>
              <Value>{limitString(collection_event?.email, 30) || "NA"}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Contact Method</Label>
              <Value>{collection_event.contact_method || "NA"}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Time to Call</Label>
              <Value>{collection_event.ms_time_to_call || "NA"}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Reason for Missed Payment</Label>
              <Value>
                {collection_event.ms_collections_reas_miss_pay || "NA"}
              </Value>
            </InfoItem>
          </InfoContainer>
          <Seperator />
          <TableContainer>
            <TableTitle>Notes</TableTitle>
            {/* <Label>{invoice.memo}</Label> */}
            <Table>
              <TableCellText>
                {collection_event.collection_notes || "No Notes to Display"}
              </TableCellText>
            </Table>
          </TableContainer>
        </ContentContainer>
        <Seperator2 />
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Adjust this value to make the modal wider */
  height: 600px;
  align-items: center;
  justify-content: flex-start;
`;

const StatusBar = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30px;
    margin: 0;
    background-color: #F2EFEF;
    border-bottom: 1px solid orange;
    &.payment {
        background-color: #DFF2DF;
        border-bottom: 1px solid green;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    width: 100%;
`;

const StatusBarText = styled.p`
    font-size: 0.7em;
    color: charcoal;
    margin: 0;
    padding: 0;
    text-align: left;
    padding-left: 20px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-bottom: 1rem;
`;

const Title = styled.h1`
    font-size: 1.3em;
    text-align: center;
    color: #354669;
    text-align: left;
    padding: 0.5em;
    font-weight: normal;
`;

const Status = styled.p`
  font-size: 0.7em;
  color: #354669;
  border-radius: 50px;
  padding: 0.5em 1em;
  text-transform: capitalize;
  margin-right: 20px;
  background-color: ${COLORS.ORANGE_LIGHT};
  color: ${COLORS.ORANGE};
  &.contacted-customer {
    background-color: #F2EFEF;
    color: #354669;
  }
`;

const InfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    width: 90%;
    height: 75px;
    padding: 0rem 5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // border: 1px solid #EDEDED;
    height: 40px;
`;

const Label = styled.p`
    font-size: 0.7em;
    color: #354669;
    margin: 0;
    padding: 0;
    text-align: left;
`;

const Value: any = styled.p`
    font-size: 0.9em;
    color: #354669;
    margin: 0;
    padding: 0;
    text-align: left;
    // text-transform: capitalize;
`;

const Seperator = styled.div`
    width: 90%;
    height: 1px;
    background-color: lightgrey;
    margin: 1rem 0rem;
`;

const Seperator2 = styled.div`
    width: 100%;
    height: 1px;
    background-color: lightgrey;
    margin-top: 3rem;
    margin-bottom: 2rem;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    width: 96%;
    border: 1px solid lightgrey;
    border-radius: 0.2rem;
    padding: 0.5rem;
    padding-top: 1rem;
    margin-top: 1rem;
    height: 100px;
`;

const TableTitle = styled.h1`
    font-size: 1em;
    text-align: center;
    color: #354669;
    text-align: left;
    font-weight: normal;
    margin: 0;
    margin-top: 1rem;
`;

const TableCellText = styled.p`
    font-size: 0.8em;
    color: #354669;
    margin: 0;
    padding: 0;
    font-style: italic;
`;