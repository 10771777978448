import React from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../Theme";
import { formatCurrency, formatDateString, countMissedPayments } from "../../../util";
import { Typography } from "@mui/material";
import { sortByDateDescending } from "../functions";

type WidgetProps = {
  lead: Lead;
};

export default function Widgets({ lead }: WidgetProps) {

  const lastPaymentAmount = lead?.payments ? lead?.payments[lead?.payments?.length - 1]?.amount ?? 0 : 0;
  const lastCollectionsActivity = sortByDateDescending(lead?.collection_activity, "timestamp")[0];
  const lastSuccessfulCollectionEvent = sortByDateDescending(lead?.collection_activity, "timestamp")?.find((c) => c.collection_outcomes === "Contacted Customer");

  const collectionsStatus = lastCollectionsActivity
    ? `${lastCollectionsActivity.collection_outcomes}: ${lastCollectionsActivity.collection_suboutcomes}`
    : lead.collections_information.collections_status || "NA";
  const lastSuccessfulContactDate =
    lastSuccessfulCollectionEvent?.timestamp ||
    formatDateString(
      lead.collections_information.successful_customer_contact_date,
    ) ||
    "NA";
  const lastAttemptedContactDate =
    lastCollectionsActivity?.timestamp ||
    formatDateString(lead.collections_information.last_contact_attempted) ||
    "NA";
  const timeToCall =
    lastSuccessfulCollectionEvent?.ms_time_to_call ||
    lead.collections_information.time_to_call ||
    "NA";

  const reasonForMissedPayment =
    lastSuccessfulCollectionEvent?.ms_collections_reas_miss_pay ||
    lead.collections_information.reason_for_missed_payment ||
    "NA";

  
  return (
    <WidgetsWrapper>
      <Column>
        <WidgetContainer>
          <WidgetHelperText>Collections Status</WidgetHelperText>
          <WidgetText>
            {collectionsStatus}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText style={{ paddingRight: 20 }}>
            Reason for Missed Payment
          </WidgetHelperText>
          <WidgetText>
            {reasonForMissedPayment}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Payment Amount</WidgetHelperText>
          <WidgetText>
            {lastPaymentAmount
              ? formatCurrency(
                  // lead.last_payment_amount
                  lastPaymentAmount,
                )
              : "NA"}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Payment Received</WidgetHelperText>
          <WidgetText>
            {lead?.payments
              ? lead.payments[lead?.payments?.length - 1]?.trandate || "NA"
              : "NA"}
          </WidgetText>
        </WidgetContainer>
      </Column>
      <Column>
        <WidgetContainer>
          <WidgetHelperText>Time to Call</WidgetHelperText>
          <WidgetText>
            {timeToCall}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Successful Contact Date</WidgetHelperText>
          <WidgetText>{lastSuccessfulContactDate}</WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Contact Attempted</WidgetHelperText>
          <WidgetText>{lastAttemptedContactDate}</WidgetText>
        </WidgetContainer>
      </Column>
    </WidgetsWrapper>
  );
}

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: flex-start;
  // border-bottom: 1px dashed #ededed;
  margin: 5px;
  width: 100%;
  gap: 5px;
  // border-radius: 5px;
  // max-width: 85%;
  overflow: hidden;
`;

const Label = styled.div`
  font-family: Gotham-Medium;
  color: #707070;
`;

const Value = styled.div`
  font-family: Gotham-Book;
  color: #707070;
`;

const WidgetHelperText = styled(Typography)`
  font-size: 0.8em;
  font-family: Gotham-Medium;
  color: #707070;
`;

const WidgetText = styled.div`
  font-size: 14px;
  font-family: Gotham-Book;
  color: #707070;
`;

const WidgetsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px;
  height: auto;
  border: 1px solid #DDDDDD;
  border-radius: 15px;
  padding: 20px;
  padding-left: 30px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: 100%;
`;