import React from 'react';
import { useLeadHistory } from '../../hooks';
import styled from "@emotion/styled";
import { HelperTextWrapper, HelperText, ColumnWrapper, HeaderInfoWrapper, HeaderTitle, PanelHeader } from '../../styled-components';
import { Loader } from '../../../../GlobalComponents';
import { COLORS } from '../../../../Theme';
import LeadsTable from "./LeadsTable";

type LeadHistoryProps = {
    teamMember: TeamMember;
};

export default function LeadHistory({ teamMember }: LeadHistoryProps) {
  const {
    data: leads,
    isLoading,
    isFetching,
  } = useLeadHistory({
    team_member_email: teamMember?.email,
  });

  return (
    <div style={{ flex: 1 }}>
      <PanelHeader>
        <HeaderInfoWrapper>
          <HeaderTitle
            style={{
              color: !teamMember ? COLORS.GREY_MEDIUM2 : COLORS.PRIMARY,
            }}
          >
            Lead history for {teamMember?.email || "No Team Member Selected"}
          </HeaderTitle>
        </HeaderInfoWrapper>
      </PanelHeader>
      <Wrapper>
        <HelperTextWrapper>
          {teamMember ? (
            <HelperText>Lead details for {teamMember?.email}</HelperText>
          ) : (
            <HelperText>Select a Team Member</HelperText>
          )}
        </HelperTextWrapper>
        {teamMember && (
          <div style={{ paddingLeft: 20 }}>
            <LeadsTable leads={leads} isLoading={isLoading || isFetching} />
          </div>
        )}
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;