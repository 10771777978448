import React, { useState } from "react";
import { PageView } from "../../../GlobalComponents";
import styled from "@emotion/styled";
import AdminNav from "../admin-nav";
import { useTeam } from "../hooks";
import TeamMebers from "../Assignments/TeamMembers";
import TeamMembersList from "../Assignments/TeamMembers/TeamMembersList";
import SearchBar from "../Assignments/TeamMembers/SearchBar";
import LeadHistory from "./leads-history";
import { HelperText, HelperTextWrapper, ColumnWrapper, SearchbarWrapper, TeamMembersHeader } from "../styled-components";

export default function Admin() {
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<TeamMember[]>([]);
  const [search, setSearch] = useState("");
  const { data, isLoading } = useTeam();

  const team_members = React.useMemo(() => {
    if(!search && !!data) return data;
    return data?.filter((m) => m?.email?.includes(search)); 
  },[search, data])

  const onTeamMemberSelect = (teamMember: TeamMember) => {
    setSelectedTeamMembers([teamMember]);
  };

  return (
    <PageView headerProps={{ isAdmin: true, rightContent: <AdminNav /> }}>
      <Container>
        <ColumnWrapper>
          <TeamMembersHeader>
            <SearchbarWrapper>
              <SearchBar
                placeholder="Search Team Members"
                onChange={setSearch}
              />
            </SearchbarWrapper>
          </TeamMembersHeader>
          <HelperTextWrapper>
            <HelperText style={{ textAlign: "center" }}>
              Select a Team Member to View their Leads
            </HelperText>
          </HelperTextWrapper>
          <TeamMembersList
            showCheckbox
            teamMembers={team_members}
            isLoading={isLoading}
            selectedTeamMembers={selectedTeamMembers}
            onTeamMemberSelect={onTeamMemberSelect}
            containerStyle={{height: "65%"}}
          />
        </ColumnWrapper>
        <LeadHistory teamMember={selectedTeamMembers[0]} />
      </Container>
    </PageView>
  );
}

const Container = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0px 0px 10px #35466933;
  height: 80vh;
  width: 100%;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  background-color: grey;
  height: 200px;
  width: 100%;
`;
