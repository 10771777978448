import React from "react";
import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function addLineBreaks(input: string | undefined): string {
  if (!input) return "";
  // Match a period followed by zero or more spaces, followed by a capital letter
  return input.replace(/\. *([^\s])/g, ".\n\n$1");
}

type CasesProps = {
  cases: Case[];
};

export default function Cases({ cases }: CasesProps) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedCase, setSelectedCase] = React.useState<Case | null>(null);

  const onClick = (c: Case) => {
    setSelectedCase(c);
    setModalOpen(true);
  };

  return (
    <CasesContainer>
      {cases.map((c) => (
        <Tooltip
          title={`Requested At: ${new Date(c.requested_at).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })} - ${c.description}`}
          arrow
        >
          <CaseBtn onClick={() => onClick(c)}>
            <CaseDot />
            <CaseText>{c?.category || "Case: Other"}</CaseText>
          </CaseBtn>
        </Tooltip>
      ))}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <ModalContent>
          <ModalHeader>
            <CaseDot style={{ marginLeft: 20 }} />
            <ModalTitle>{selectedCase?.category || "Other"}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <DateText>
              {selectedCase?.requested_at &&
                `Requested At: ${new Date(
                  selectedCase?.requested_at,
                ).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })}`}
            </DateText>
            <ModalText style={{ padding: 20 }}>
              {addLineBreaks(selectedCase?.description)}
            </ModalText>
          </ModalBody>
          <XButton onClick={() => setModalOpen(false)}>
            <CloseOutlinedIcon />
          </XButton>
        </ModalContent>
      </Modal>
    </CasesContainer>
  );
}

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const CaseBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  background-color: #f0f4f5;
  padding: 0.5em;
  border-radius: 1em;
  margin: 0.5em;
  height: 20px;
  cursor: pointer;
`;

const CaseText = styled.p`
  font-size: 0.8em;
  color: #354669;
  margin: 0;
  text-transform: capitalize;
`;

const CaseDot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #354669;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-height: 200px;
  align-items: center;
  border-radius: 0.5rem;
  position: relative;
  background-color: #fff;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: #f0f4f5;
  height: 50px;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const ModalTitle = styled.h1`
  font-size: 0.9rem;
  color: #354669;
  margin: 0;
  text-transform: capitalize;
  font-weight: normal;
  width: 100%;
`;

const ModalBody = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow: auto;
  max-height: 50vh;
`;

const ModalText = styled.div`
  font-size: 0.9rem;
  color: #354669;
  margin: 0;
  text-transform: capitalize;
  font-weight: normal;
  white-space: pre-wrap; /* Preserve newlines and spaces */
`;

const XButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }
`;

const DateText = styled.div`
  font-size: 0.8rem;
  color: #354669;
`;
