import { useQuery } from "@tanstack/react-query";
import mockLeads from "../mock-leads.json";

const getLeadHistory = async (team_member_email: string): Promise<TeamViewerLead[]> => {
    return new Promise((res,rej) => {
        setTimeout(() => {
          const finalMockedLeads = mockLeads.map((l, i) =>
            i !== 0 ? l : { ...l, isCurrent: true },
          );
          //@ts-ignore
          res(finalMockedLeads as TeamViewerLead[]);
        }, 1000);
    })
};

const useLeadHistory = ({team_member_email}: {team_member_email: string}) => {
    return useQuery({
        queryKey: ["lead-history", team_member_email],
        queryFn: () => getLeadHistory(team_member_email),
        enabled: !!team_member_email
    })
};

export default useLeadHistory;
