import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import LinearProgress from "@mui/material/LinearProgress"; // Importing the LinearProgress component
import { COLORS } from "../../Theme";

type OutcomeModalProps = {
  open: boolean;
};

export default function LoadingModal({ open }: OutcomeModalProps) {
  const [progress, setProgress] = useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Modal
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalContent>
        <DialogTitle style={{ fontFamily: "Gotham-Medium", color: COLORS.PRIMARY }}>
          Getting Lead
        </DialogTitle>
        <DialogTitle style={{ fontFamily: "Gotham-Book", fontSize: 18 }}>
          Please wait while we get your lead...
        </DialogTitle>

        <ProgressText>{progress.toFixed(0)}%</ProgressText>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            width: "70%",
            height: "10px",
            borderRadius: "5px",
            backgroundColor: "rgba(51, 102, 153, 0.3)",
            "& .MuiLinearProgress-bar": { backgroundColor: COLORS.PRIMARY },
          }}
        />
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 500px;
  height: 300px;
  padding: 5rem 3rem;
  align-items: center;
  background-color: white;
  border-radius: 0.3em;
`;

const ProgressText = styled.p`
  color: ${COLORS.PRIMARY};
  font-size: 1.2rem;
  font-family: Gotham-Bold;
`;
