import { useState, useMemo } from "react";
import styled from "@emotion/styled";
import TeamMembersList from "./TeamMembersList";
import { COLORS } from "../../../../Theme";
import SearchBar from "./SearchBar";
import { Checkbox } from "@mui/material";

type TeamMembersProps = {
    teamMembers: TeamMember[] | undefined;
    isLoading: boolean;
    selectedTeamMembers: TeamMember[];
    onTeamMemberSelect: (teamMember: any) => void;
    onSelectAll: () => void;
    selectEnabled?: boolean;
};

export default function TeamMebers({ teamMembers, isLoading, onTeamMemberSelect, onSelectAll, selectedTeamMembers, selectEnabled=false }: TeamMembersProps) {
  const [search, setSearch] = useState("");

//   const { data: teamMembers, isLoading: assigneesLoading } = useQuery({
//     queryKey: ["assignees"],
//     queryFn: getTeamMembers,
//   });

  const searchedTeamMembers: TeamMember[] | undefined = useMemo(() => {
    return teamMembers?.filter((member: any) =>
      member.email.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, teamMembers]);

  return (
    <Wrapper>
      <TeamMembersHeader>
        <SearchbarWrapper>
          <SearchBar placeholder="Search Team Members" onChange={setSearch} />
        </SearchbarWrapper>
      </TeamMembersHeader>
      <ContentWrapper>
        <HelperTextWrapper>
          <HelperText>
            Select Team Members to assign them to a Search
          </HelperText>
          {selectEnabled && (
            <Checkbox
              checked={selectedTeamMembers.length === teamMembers?.length}
              onChange={onSelectAll}
              sx={{ paddingTop: 2, paddingRight: 4 }}
            />
          )}
        </HelperTextWrapper>
        <TeamMembersList
          teamMembers={searchedTeamMembers || teamMembers}
          selectedTeamMembers={selectedTeamMembers}
          isLoading={isLoading}
          onTeamMemberSelect={onTeamMemberSelect}
          showCheckbox={selectEnabled}
        />
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  align-items: center;
  background-color: #fff;
//   border: 1px solid #fff;
  border-right: 2px solid ${COLORS.GREY_MEDIUM3};
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  overflow: hidden;
`;

const TeamMembersHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${COLORS.GREY_MEDIUM3};
  width: 100%;
  background-color: #f5f9fa;
  padding-bottom: 17.5px;
`;

const HelperTextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const HelperText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.GREY_MEDIUM2};
  width: 100%;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
`;

const SearchbarWrapper = styled.div`
    width: "100%";
    padding-top: 25px;
    display: flex;
    justify-content: center;
`;