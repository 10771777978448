import React from "react";
import MUIModal, { ModalProps as MuiModalProps } from '@mui/material/Modal';
import styled from "@emotion/styled";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

type ModalProps = MuiModalProps & {
    children: React.ReactNode;
    setOpen: (open: boolean) => void;
    width?: string;
    height?: string;
    buttonColor?: string;
    showXButton?: boolean;
    title?: string;
    headerColor?: string;
    headerTextColor?: string;
    showHeader?: boolean;
    onClose?: () => void;
};

export default function Modal2({
  children,
  setOpen,
  width = "475px",
  height = "600px",
  buttonColor = "#F0F4F5",
  showXButton = true,
  title = "",
  headerColor = "#F0F4F5",
  headerTextColor = "#354669",
  showHeader = false,
  onClose,
  ...rest
}: ModalProps) {

  const onXButtonClick = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <MUIModal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      {...rest}
    >
      <ModalBody width={width} height={height}>
        {showHeader && 
          <ModalHeader backgroundColor={headerColor} textColor={headerTextColor}>
            <TagDot style={{ marginLeft: 20 }} />
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
        }
        {children}
        {showXButton && (
          <XButton onClick={onXButtonClick} buttonColor={buttonColor}>
            <CloseOutlinedIcon style={{color: "#fff"}} />
          </XButton>
        )}
      </ModalBody>
    </MUIModal>
  );
}

interface ModalBodyProps {
    width: string;
    height: string;
}

const ModalBody = styled.div<ModalBodyProps>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 40px;
    position: relative;
    background-color: #fff;
`;

interface XButtonProps {
    buttonColor: string;
}

const XButton = styled.div<XButtonProps>`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        background-color: #EDEDED;
    }
`;

const ModalHeader = styled.div<{ backgroundColor: string; textColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border-radius: 40px;
`;

const ModalTitle = styled.h1`
  font-size: 0.9rem;
  color: #354669;
  margin: 0;
  text-transform: capitalize;
  font-weight: normal;
  width: 100%;
`;

const TagDot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #354669;
`;