import { useState } from "react";
import { Loader, Modal } from "../../GlobalComponents";
import { useCustomerInfo, assignLeadByID } from "./api";
import { TextField, Typography, Button } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../Theme";
import { useMutation } from "@tanstack/react-query";
import { useAuthSession } from "../../Auth";
import InputMask from 'react-input-mask';
import StartIcon from '@mui/icons-material/Start';
import SearchIcon from '@mui/icons-material/Search';

export default function LookupCustomerModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (v: boolean) => void;
}) {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const { data, isLoading, isFetching } = useCustomerInfo({ phone });
  const { mutateAsync, isPending } = useMutation({ mutationFn: assignLeadByID });
  const { email } = useAuthSession();
  console.log("data", data)

  const onGoToLead = () => {
    // navigate(`/lead/${data?.internal_id}`);
    mutateAsync({ internal_id: data?.internal_id || "", email }).then((res) => {
      navigate(`/lead/${data?.internal_id}`);
    });
  };

  return (
    <Modal
      height={!data ? "35vh" : "60vh"}
      title="Get lead by Phone"
      open={open}
      setOpen={setOpen}
      xButtonVariant="2"
    >
      <Wrapper>
        <div
          style={{
            color: COLORS.PRIMARY,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <SearchIcon />
          <Typography
            style={{
              color: COLORS.PRIMARY,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            Look Up Customer
          </Typography>
        </div>
        <Typography style={{ color: "grey" }}>
          Type in Cutomer phone number to look up a Customer.
        </Typography>
        <InputMask
          value={phone}
          mask="(999) 999-9999"
          onChange={(e) => setPhone(e.target.value)}
        >
          {() => {
            return (
              <TextField
                label="Enter Customer Phone..."
                sx={{ width: "100%" }}
              />
            );
          }}
        </InputMask>
        {isLoading || isFetching || isPending ? (
          <Loader />
        ) : (
          data && (
            <CustomerInfoWrapper>
              <Typography style={{ fontWeight: "bold", color: COLORS.PRIMARY }}>
                Customer Info:
              </Typography>
              <Typography>Customer Name: {data.customer_name}</Typography>

              <Typography>
                Address: {data.address_line_1} {data.city}, {data.state}{" "}
                {data.zip}
              </Typography>
              <Typography>Phone: {data.phone}</Typography>
              <Typography>Email: {data.email}</Typography>
              <Button endIcon={<StartIcon />} onClick={onGoToLead}>
                Go to Lead
              </Button>
            </CustomerInfoWrapper>
          )
        )}
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CustomerInfoWrapper = styled.div`
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const AddressWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

