import React from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../Theme";
import dayjs from "dayjs";

type NoteProps = {
    note: Note;
    onNoteClick: (note: Note) => void;
};

export default function Note({note, onNoteClick}: NoteProps) {

  return (
    <NoteCard onClick={() => onNoteClick(note)}>
      <NoteHeader>
        {/* <NoteTitle>Outcome: {note.outcome}</NoteTitle> */}
        <NoteDate>{dayjs(note?.date)?.format("MM-DD-YYYY")} {note?.agent && ` | ${note.agent}`}</NoteDate>
      </NoteHeader>
      <NoteBody>
        <NoteText>{note.description}</NoteText>
      </NoteBody>
      <NoteFooter>
        {/* <NoteEdit>Created By: {note.created_by}</NoteEdit> */}
      </NoteFooter>
    </NoteCard>
  );
}

const NoteCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #DDDDDD;
  padding: 1rem;
  border-radius: 15px;
  // border-radius: 0.5rem;
  width: 98%;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const NoteHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  // margin-bottom: 1rem;
`;

const NoteBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-bottom: 1rem;
  padding-top: 10px;
`;

const NoteFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-bottom: 1rem;
`;

const NoteTitle = styled.div`
  // font-weight: bold;
  font-size: 1.2rem;
  margin-right: 1rem;
  color: grey;
  text-transform: capitalize;
  margin-bottom: 5px;
`;

const NoteDate = styled.div`
  font-size: 1rem;
  color: ${COLORS.PRIMARY};
`;

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
`;

const NoteEdit = styled.div`
  font-size: 1rem;
  color: grey;
  cursor: pointer;
`;
