import { useState, useEffect } from "react";
import {
  ToolContainer,
  HelperText,
} from "./styled-components";
import { FormControl, TextField, Button, Typography } from "@mui/material";
import { cleanLead, unlockLead } from "../api";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAuthSession } from "../../../Auth";

export default function LeadTools({
  setOpen,
}: {
  setOpen: (v: boolean) => void;
}) {
  const navigate = useNavigate();
  const { email, clearProxyLogin } = useAuthSession();
  const [enteredInternalId, setEnteredInternalId] = useState("");

  const {
    mutate: onCleanMutate,
  } = useMutation({
    mutationFn: cleanLead,
    onSuccess: () => {
      window.alert(`Lead ${enteredInternalId} cleaned successfully`);
    //   setEnteredInternalId("");
    },
  });

  const {
    mutate: unlockSpecificLeadMutation,
    isPending: unlockSpecificLeadLoading,
  } = useMutation({
    mutationFn: () => unlockLead(email, enteredInternalId),
    onSuccess: () => {
      window.alert(`Lead ${enteredInternalId} unlocked successfully`);
    //   setEnteredInternalId("");
    },
  });

  const onClean = () => {
    onCleanMutate(enteredInternalId);
  };

  const onGetByID = () => {
    clearProxyLogin();
    setOpen(false);
    navigate(`/lead/${enteredInternalId}`);
  };

  const onUnlock = () => {
    unlockSpecificLeadMutation();
  };

  return (
    <FormControl>
      <ToolContainer>
        <HelperText>Lead Tools:</HelperText>
        <TextField
          placeholder="Enter Internal ID"
          onChange={(e) => setEnteredInternalId(e.target.value)}
          size="small"
        />
        <Button
          variant="contained"
          disabled={!enteredInternalId.length}
          onClick={onGetByID}
          sx={{ height: 30, width: 194 }}
        >
          <Typography sx={{ color: "#fff", fontSize: 14 }}>Get Lead</Typography>
        </Button>
        <Button
          variant="contained"
          disabled={!enteredInternalId.length}
          onClick={onUnlock}
          sx={{ height: 30, width: 194 }}
        >
          <Typography sx={{ color: "#fff", fontSize: 14 }}>
            Unlock Lead
          </Typography>
        </Button>
        <Button
          variant="contained"
          disabled={!enteredInternalId.length}
          onClick={onClean}
          sx={{ height: 30, width: 194 }}
        >
          <Typography sx={{ color: "#fff", fontSize: 14 }}>
            Clean Lead
          </Typography>
        </Button>
      </ToolContainer>
    </FormControl>
  );
}
