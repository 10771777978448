import React, { useState } from "react";
import PersonChip from "./PersonChip";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { COLORS } from "../../../../Theme";
import { Header, Loader } from "../../../../GlobalComponents";
import ConfirmModal from "../ConfirmModal";

type SavedSearchesListProps = {
  savedSearch: SavedSearch | null;
  assignees: TeamMember[] | [];
  onTeamMemberSelect: (teamMember: TeamMember) => void;
  searchAssignedTeamMembers: TeamMember[] | undefined;
  onReset: () => void;
  onSave: () => void;
  isLoading: boolean;
};

export default function SavedSearchPanel({ savedSearch, assignees, onTeamMemberSelect, onReset, onSave, isLoading, searchAssignedTeamMembers }: SavedSearchesListProps) {

  const [revertModalOpen, setRevertModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const assigneesMatchSavedSearchAssignees = searchAssignedTeamMembers?.every((person) =>
    assignees.find((assignee) => assignee.email === person.email)
  ) && assignees.length === searchAssignedTeamMembers.length;
  const isChanged = savedSearch && !assigneesMatchSavedSearchAssignees;

  const onRevertChanges = () => {
    setRevertModalOpen(true);
  };

  const onSaveHelper = () => {
    setSaveModalOpen(true);
  };

  if(isLoading) {
    return (
      <div style={{ height: 600 }}>
        <Loader text="Loading Team Members" />
      </div>
    );
  }

  return (
    <Container>
      <PanelHeader>
        <HeaderInfoWrapper>
          <HeaderTitle
            style={{
              color: !savedSearch ? COLORS.GREY_MEDIUM2 : COLORS.PRIMARY,
            }}
          >
            {savedSearch?.readable_name || "No Search Selected"}
          </HeaderTitle>
          {savedSearch && <HelperText>{assignees.length} assigned</HelperText>}
          <HelperText>{isChanged ? "Changes not saved!" : ""}</HelperText>
        </HeaderInfoWrapper>
      </PanelHeader>
      <HelperTextWrapper>
        <HelperText
          style={{
            textAlign: "left",
            width: "100%",
            paddingLeft: 20,
            paddingTop: 5,
          }}
        >
          Review assignees before saving changes
        </HelperText>
      </HelperTextWrapper>
      <AssigneesContainer>
        {assignees.map((person) => (
          <PersonChip
            key={person.id}
            person={person}
            onClick={onTeamMemberSelect}
          />
        ))}
      </AssigneesContainer>
      <BottomContainer>
        <HelperText style={{ marginBottom: 20 }}>
          {isChanged ? "You have unsaved changes" : "No changes made"}
        </HelperText>
        <ButtonsContainer>
          <Button
            variant="outlined"
            color="primary"
            onClick={onRevertChanges}
            disabled={!isChanged}
            sx={{width: 200, height: 30, fontFamily: "Gotham-Medium", paddingTop: 1}}
          >
            Revert
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!isChanged}
            onClick={onSaveHelper}
            sx={{width: 200, height: 30 , fontFamily: "Gotham-Medium", paddingTop: 1}}
          >
            Save Changes
          </Button>
        </ButtonsContainer>
      </BottomContainer>
      <ConfirmModal 
        title="Revert Changes?"
        description="Are you sure you want to revert changes?"
        open={revertModalOpen}
        setOpen={setRevertModalOpen}
        onConfirm={onReset}
      />
      <ConfirmModal 
        title="Save Changes?"
        description="Are you sure you want to save changes?"
        open={saveModalOpen}
        setOpen={setSaveModalOpen}
        onConfirm={onSave}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  background-color: #fff;
  border-right: 1px solid lightgrey;
  overflow: hidden;
  position: relative;
`;

const AssigneesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 25px;
  width: 100%;
  height: 200px;
  overflow: auto;
  border-bottom: 1px dotted lightgrey;
  align-content: flex-start;
`;

const PanelHeader = styled.div`
  border-bottom: 2px solid ${COLORS.GREY_MEDIUM3};
  width: 100%;
  height: 75px;
  overflow: hidden;
`;

const HeaderInfoWrapper = styled.div`
  padding: 1rem;
  background-color: #f5f9fa;
  height: 100%;
`;

const HelperTextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 1rem;
`;

const HelperText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.GREY_MEDIUM2};
`;

const HeaderTitle = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 14px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  color: grey;
  font-size: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  width: 275px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  width: 275px;
  padding-bottom: 3rem;
`;