import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NotFound, PrivateRoute, AdminRoute, AuthProvider } from "./Auth";
import { ThemeProvider } from "@mui/material";
import theme from "./Theme";
import { ContextProvider } from "./Context";
import TeamViewer from "./Views/Admin/team-viewer";


import {
  Dashboard,
  Invoice,
  Lead,
  LeadHistory,
  Admin,
} from "./Views";

import './App.css';

// Create a client
const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  }
);

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <ContextProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/lead/:id" element={<Lead />} />
                    <Route path="/lead" element={<Lead />} />
                    <Route path="/lead-history" element={<LeadHistory />} />
                    <Route
                      path="/invoice/customer-id/:customer_id/internalid/:invoice_id"
                      element={<Invoice />}
                    />
                  </Route>
                  <Route
                    path="/admin"
                    element={<AdminRoute />}
                  >
                    <Route path="/admin" element={<Admin />} />
                  </Route>
                  <Route
                    path="/admin"
                    element={<AdminRoute />}
                  >
                    <Route path="/admin/team-view" element={<TeamViewer />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </ContextProvider>
          </ThemeProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
