import styled from "@emotion/styled";

export const GridColumnHeader = styled.div`
    font-family: Gotham-Medium;
    color: #707070;
`;

export const GridCellText = styled.div`
    font-family: Gotham-Book;
    color: #707070;
`;