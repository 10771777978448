import React, { useState } from "react";
import { PageView } from "../../GlobalComponents";
import styled from "@emotion/styled";
import Assignments from "./Assignments";
import AdminNav from "./admin-nav";

export default function Admin() {
  return (
    <PageView headerProps={{ isAdmin: true, rightContent: <AdminNav /> }}>
      <Assignments />
    </PageView>
  );
}
