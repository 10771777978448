import React from "react";
import styled from "@emotion/styled";
import { formatCurrency } from "../../../util";
import { Typography } from "@mui/material";

type LeadInfoBannerProps = {
  missed_payments: string | number;
  days_overdue: string | number;
  amount_overdue: string | number;
};

export default function LeadInfoBanner({
  missed_payments,
  days_overdue,
  amount_overdue,
}: LeadInfoBannerProps) {
  return (
    <Wrapper>
      <Item>
        <BannerText>Missed Payments</BannerText>
        <BannerValue>{missed_payments}</BannerValue>
      </Item>
      <Item>
        <BannerText>Days Overdue</BannerText>
        <BannerValue>{days_overdue}</BannerValue>
      </Item>
      <Item>
        <BannerText>Amount Overdue</BannerText>
        <BannerValue>{formatCurrency(amount_overdue)}</BannerValue>
      </Item>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #de9696;
  display: flex;
  align-items: center;
  // height: 40px; 
  background: #fff;
  padding: 10px;
  padding;
  gap: 20px;
`;

const Item = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

`;

const BannerText = styled(Typography)`
  font-family: Gotham-Medium;
  color: #de9696;
  font-size: 18px;
  line-height: 0.9;

`;

const BannerValue = styled(Typography)`
  font-family: Gotham-Book;
  color: #000;
  font-size: 18px;
`;
