import React from 'react';
import { Button, Typography } from '@mui/material';
import styled from "@emotion/styled";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function AdminNav() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const onClick = (route: string) => {
    navigate(route);
  };

  return (
    <Wrapper>
        <Button variant={currentPath === "/admin" ? "outlined" : "text"} onClick={() => onClick("/admin")}>Safe Searches</Button>
        <Button variant={currentPath === "/admin/team-view" ? "outlined" : "text"} onClick={() => onClick("/admin/team-view")}>Team View</Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    gap: 10px;
`;
