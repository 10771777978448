const formatPhone = (phone: string): string => {
    // Remove all non-numeric characters
    let cleaned = phone.replace(/\D/g, '');
  
    // Remove leading '1' if the number has more than 10 digits
    if (cleaned.length > 10 && cleaned.startsWith('1')) {
      cleaned = cleaned.slice(1);
    }
  
    // Ensure we have exactly 10 digits
    if (cleaned.length !== 10) return phone;
  
    // Format as (XXX) XXX-XXXX
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

export default formatPhone;
