import React from 'react';
import styled from "@emotion/styled";
import Menu from "./Menu";
import { devEmails } from "../../config";
import { useMsal } from '@azure/msal-react';

export type HeaderProps = {
  rightContent?: React.ReactNode;
  devToolsOpen?: boolean;
  setDevToolsOpen?: (v: boolean) => void;
  isAdmin?: boolean;
};

export default function Header({
  rightContent,
  devToolsOpen,
  setDevToolsOpen,
  isAdmin,
}: HeaderProps) {
  return (
    <Wrapper>
      <LeftContent>
        <Menu devToolsOpen={devToolsOpen} setDevToolsOpen={setDevToolsOpen} />
        <TitleWrapper>
          <Title>Collections</Title>
          <Title2>Web</Title2>
          <Title2>Portal{isAdmin && ":"}</Title2>
          {isAdmin && <Title2>Admin</Title2>}
        </TitleWrapper>
      </LeftContent>
      <RightContent>{rightContent}</RightContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    background-color: #D6F5FF;
    height: 90px;
    display: flex;
    justify-content: space-between;
    padding-left: 40px; 50px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 90px;
`;

const Title = styled.div`
  font-family: Gotham-Black;
  color: #336699;
  font-size: 35px;
`;

const Title2 = styled.div`
  font-family: Gotham-Book;
  color: #336699;
  font-size: 35px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  position: relative;
`;

const RightContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 30px;
`;
