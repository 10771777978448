import React from "react";
import { Checkbox } from "@mui/material";
import styled from "@emotion/styled";
import PersonIcon from "../PersonIcon";
import { COLORS } from "../../../../Theme";
import { Loader } from "../../../../GlobalComponents";
import CheckIcon from '@mui/icons-material/Check';

const limitString = (str: string, limit: number) => {
  return str.length > limit ? str.substring(0, limit) + "..." : str;
};

type TeamMembersListProps = {
  teamMembers: TeamMember[] | undefined;
  selectedTeamMembers: TeamMember[];
  isLoading: boolean;
  onTeamMemberSelect: (teamMember: any) => void;
  showCheckbox?: boolean;
  containerStyle?: React.CSSProperties;
};

export default function TeamMembersList({
  teamMembers,
  isLoading,
  onTeamMemberSelect,
  selectedTeamMembers,
  showCheckbox = false,
  containerStyle,
}: TeamMembersListProps) {
  return (
    <Wrapper style={{ ...containerStyle }}>
      {isLoading ? (
        <div style={{ height: 600 }}>
          <Loader text="Loading Team Members" />
        </div>
      ) : (
        teamMembers &&
        teamMembers.map((teamMember: TeamMember, index: number) => {
          const isChecked =
            selectedTeamMembers.find(
              (selectedTeamMember) =>
                selectedTeamMember.email === teamMember.email,
            ) !== undefined;

          return (
            <TeamMemberRow
              key={teamMember.id}
              onClick={() => showCheckbox && onTeamMemberSelect(teamMember)}
            >
              <PersonIcon person={teamMember} size="small" />
              <InfoWrapper
                style={{ cursor: showCheckbox ? "pointer" : "default" }}
              >
                {/* <Name>{teamMember.name}</Name> */}
                <Email>{limitString(teamMember.email, 30)}</Email>
              </InfoWrapper>
              {showCheckbox && isChecked ? (
                <CheckIcon style={{ color: "#00c965" }} />
              ) : (
                <div style={{ width: 20, height: 20 }} />
              )}
            </TeamMemberRow>
          );
        })
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 72%;
  align-items: center;
  margin: 5px;
  background-color: #fff;
  border: 1px solid #fff;
  // cursor: grab;
  touch-action: none;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 8px;
`;

const TeamMemberRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #DDDDDD;
  padding: .4rem;
  width: 90%;
  padding-left: 15px;
  border-radius: 15px;
  cursor: pointer;
`;

const Name = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 14px;
  text-overflow: ellipsis;
`;

const Email = styled.div`
  color: #333;
  font-size: 12px;
  text-overflow: ellipsis;
`;


const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // background-color: orange;
  width: 60%;
  padding-right: 25px;
`;