import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Status } from "../../GlobalComponents";
import { formatCurrency } from "../../util";
import styled from "@emotion/styled";
import { Title } from "./components";
import { GridColumnHeader, GridCellText } from "./components/styled-components";
import { sortByDateDescending } from "./functions";

const columns: GridColDef[] = [
  {
    field: "invoice_#",
    headerName: "Invoice #",
    width: 140,

    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Invoice #</GridColumnHeader>,
  },
  {
    field: "internalid",
    headerName: "Internal ID",
    width: 140,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Internal ID</GridColumnHeader>,
  },

  {
    field: "date",
    headerName: "Date",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Date</GridColumnHeader>,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 140,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{formatCurrency(params.value)}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Amount</GridColumnHeader>,
  },
  {
    field: "invoice_type",
    headerName: "Type",
    width: 120,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Type</GridColumnHeader>,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <Status status={params.value} />
    ),
    renderHeader: () => <GridColumnHeader>Status</GridColumnHeader>,
  },
  { field: "memo", headerName: "Memo", width: 400, // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Memo</GridColumnHeader>, },
];

type InvoicesListProps = {  
    invoices: Invoice[];
    oppnum: string;
    onClick: (invoice: Invoice) => void;
};

export default function InvoicesList({invoices, oppnum, onClick}:InvoicesListProps) {
  const onRowClick = (params: any, event: any) => {
    const invoice = invoices.find(
      (invoice) => invoice.internalid === params.row.internalid,
    );
    onClick(invoice as Invoice);
  };

  return (
    <div style={{ height: 400, width: "100%", paddingBottom: 35 }}>
      <Title>Invoices</Title>
      {invoices.length === 0 ? (
        <NoteText>No invoices found</NoteText>
      ) : (
        <DataGrid
          rows={sortByDateDescending(invoices, "date")}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection={false}
          onRowClick={onRowClick}
          getRowId={(row) => row.internalid}
          sx={{
            borderRadius: 4,
            padding: 1,
            paddingLeft: 3,
            border: "1px solid #DDDDDD",
            "& .MuiDataGrid-row": { cursor: "pointer" },
          }}
        />
      )}
    </div>
  );
}

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
  padding-left: 10px;
`;
