import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import styled from "@emotion/styled";
import { Status } from "../../GlobalComponents";
import { formatCurrency } from "../../util";
import { Title } from "./components";
import { GridColumnHeader, GridCellText } from "./components/styled-components";
import { sortByDateDescending } from "./functions";

const columns: GridColDef[] = [
  {
    field: "transaction_id",
    headerName: "Transaction ID",
    width: 140, // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Transaction ID</GridColumnHeader>,
  },
  {
    field: "internalid",
    headerName: "Internal ID",
    width: 140, // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Internal ID</GridColumnHeader>,
  },
  {
    field: "trandate",
    headerName: "Date",
    width: 160, // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <GridCellText>{params.value}</GridCellText>
    ),
    renderHeader: () => <GridColumnHeader>Date</GridColumnHeader>,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <div style={{ paddingLeft: 5 }}>
        <GridCellText>{formatCurrency(params.value) || "--"}</GridCellText>
      </div>
    ),
    renderHeader: () => <GridColumnHeader>Amount</GridColumnHeader>,
  },
  {
    field: "type",
    headerName: "Transaction Type",
    width: 160, // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <div style={{ paddingLeft: 10 }}>
        <GridCellText>{params.value}</GridCellText>
      </div>
    ),
    renderHeader: () => <GridColumnHeader>Transaction Type</GridColumnHeader>,
  },
  {
    field: "payment_method",
    headerName: "Payment Method",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <div style={{ paddingLeft: 10 }}>
        <GridCellText>{params.value}</GridCellText>
      </div>
    ),
    renderHeader: () => <GridColumnHeader>Payment Method</GridColumnHeader>,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) =>
      params.value ? (
        <Status status={params.value.toLowerCase()} />
      ) : (
        <div>NA</div>
      ),
    renderHeader: () => (
      <GridColumnHeader style={{ paddingLeft: 15 }}>Status</GridColumnHeader>
    ),
  },
];

type PaymentTransactionsProps = {
  transactions: Transactions;
  onClick?: (invoice: Invoice) => void;
};

export default function PaymentTransactions({
  transactions,
  onClick,
}: PaymentTransactionsProps) {
  return (
    <div style={{ height: 400, width: "100%", paddingBottom: 35 }}>
      <Title>Payment Transactions</Title>
      {transactions.length === 0 ? (
        <NoteText>No Transactions Found</NoteText>
      ) : (
        <DataGrid
          rows={sortByDateDescending(transactions, "trandate")}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection={false}
          getRowId={(row) => row.transaction_id}
        />
      )}
    </div>
  );
}

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
  padding-left: 10px;
`;
