import React from 'react'
import { useMutation } from '@tanstack/react-query';
import { notifyManager } from './api';
import { useAuthSession } from '../../Auth';
import { Loader } from '../../GlobalComponents';
import { Button, Typography} from "@mui/material";
import styled from "@emotion/styled";
import NotificationsIcon from '@mui/icons-material/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { COLORS } from "../../Theme";
import { useNavigate } from "react-router-dom";

export default function QueueEmpty({ onRetry }: { onRetry: () => void }) {
  const { email } = useAuthSession();
  const navigate = useNavigate();

  const {
    mutate: notifyManagerMutate,
    isPending: notifyManagerPending,
    isSuccess: notifyManagerSuccess,
  } = useMutation({
    mutationFn: notifyManager,
  });

  const onNotifyManager = () => {
    notifyManagerMutate({ email });
  };

  if (notifyManagerPending) {
    return (
      <ErrorWrapper style={{paddingTop: 100}}>
        <Loader text="Notifying Manager..." />
      </ErrorWrapper>
    );
  }

  if (notifyManagerSuccess) {
    return (
      <ErrorWrapper style={{ paddingTop: 100 }}>
        <Typography
          className="helper-text"
          sx={{
            fontWeight: "bold",
            color: COLORS.PRIMARY,
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(90deg, #5514B4, #FF80FF)`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: 26,
            textAlign: "center",
          }}
        >
          Your manager will reach out to you soon!
        </Typography>
        <Button
          style={{ width: 240 }}
          variant="outlined"
          onClick={() => navigate("/")}
          startIcon={<ArrowBackIcon />}
        >
          Return to Dashboard
        </Button>
      </ErrorWrapper>
    );
  }

  return (
    <ErrorWrapper>
      <div>
        <Typography
          sx={{
            fontWeight: "bold",
            color: COLORS.PRIMARY,
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(90deg, #5514B4, #FF80FF)`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: 26,
            textAlign: "center",
          }}
          className="helper-text"
        >
          You’ve reached the end of your lead list!
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            color: COLORS.PRIMARY,
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(180deg, #5514B4, #FF80FF)`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: 26,
            textAlign: "center",
            borderBottomWidth: 1,
            borderBottomColor: "#FFCC66",
          }}
          className="helper-text"
        >
          Nice work!
        </Typography>
      </div>
      <Typography
        sx={{
          fontWeight: "bold",
          color: COLORS.PRIMARY,
          backgroundcolor: "primary",
          backgroundImage: `linear-gradient(180deg, #CD5CF9, #FE756E)`,
          backgroundSize: "100%",
          backgroundRepeat: "repeat",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: 26,
          textAlign: "center",
          borderBottomWidth: 1,
          borderBottomColor: "#FFCC66",
          fontStyle: "italic",
        }}
        className="helper-text"
      >
        Let’s kick things off again!
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <Button
          style={{ width: 200 }}
          variant="outlined"
          onClick={() => onNotifyManager()}
          startIcon={<NotificationsIcon />}
          sx={{
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(90deg, #CD5CF9, #9D33D3)`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            color: "#fff",
            border: "none",
            borderRadius: 25,
          }}
        >
          Notify Manager
        </Button>
        <Button
          style={{ width: 200 }}
          variant="outlined"
          onClick={onRetry}
          startIcon={<RefreshIcon />}
          sx={{
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(90deg, #D12A24, #FE756E)`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            color: "#fff",
            border: "none",
            borderRadius: 25,
          }}
        >
          Try again
        </Button>
      </div>
      <div style={{paddingTop: 30}}>
        <img
            style={{ width: 220, height: 260 }}
            src={require("./assets/confetti-graphic.png")}
        />
      </div>
    </ErrorWrapper>
  );
}

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  gap: 15px;
  background: #d6f5ff;

  .error-text {
    color: red;
  }

  .helper-text {
  }
`;