import styled from "@emotion/styled";
import { COLORS } from "../../../Theme";

export const HelperTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const HelperText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.GREY_MEDIUM2};
  width: 100%;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  align-items: center;
  background-color: #fff;
  border-right: 2px solid ${COLORS.GREY_MEDIUM3};
  overflow: hidden;
`;

export const TeamMembersHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${COLORS.GREY_MEDIUM3};
  width: 100%;
  background-color: #f5f9fa;
  padding-bottom: 17.5px;
`;

export const SearchbarWrapper = styled.div`
  width: "100%";
  padding-top: 25px;
  display: flex;
  justify-content: center;
`;

export const HeaderInfoWrapper = styled.div`
  padding: 1rem;
  background-color: #f5f9fa;
  height: 100%;
`;

export const HeaderTitle = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 14px;
  font-weight: bold;
`;

export const PanelHeader = styled.div`
  border-bottom: 2px solid ${COLORS.GREY_MEDIUM3};
  width: 100%;
  height: 75px;
  overflow: hidden;
`;