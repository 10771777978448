import axios from 'axios';

const TEST_TAGS = [
  
];

const getOutcomeOptions = async (): Promise<OutcomeData> => {
  const endpoint = "/collections/options";
    
    try {
      const res = await axios.get(endpoint);
      // res.data.collection_status = additionalOptions.concat(res.data.collection_status);
      return res.data as OutcomeData;
    } catch(error) {
      console.error("Error fetching outcome options:", error);
      throw new Error("Failed to fetch outcome options. Please try again later.");
    }
};


const getLead = async (
  agent_email: string,
  internal_id?: string,
  adminAccess?: boolean,
): Promise<Lead | null> => {

  
  const endpoint = internal_id
    ? `/collections/lead-info/${internal_id}`
    : `/collections/lead/${agent_email}`;

  if (internal_id && !adminAccess)
    throw new Error(
      "Unauthorized Access.  User does not have permission to get by id.",
    );

  try {
    const response = await axios.get(endpoint);

    // Check for 204 status or missing data
    if (response.status === 204 || !response?.data) {
      return null;
    }

    const mappedActivity = response.data?.collection_activity?.map(
      (a: CollectionEvent, idx: number) => {
        return { ...a, id: idx.toString(), date: "7/11/2024" };
      },
    );

    return { ...response.data, collection_activity: mappedActivity } as Lead;
  } catch (error: any) {
    // Handle the error gracefully
    console.error("Error fetching lead:", error);
    throw new Error(
      error.response?.data?.message ??
        "An unspecified error occurred. Please try again later.",
    );
  }
};

const unlockLead = async (agent_email: string, customerID: string): Promise<Lead> => {
  const endpoint = `/collections/lead/unlock`;

  try {
    const response = await axios.post(endpoint, {
      email: agent_email,
      internal_id: customerID
    });
    return response.data as Lead;
  } catch (error: any) {
    // Handle the error gracefully
    console.error("Error fetching lead:", error);
    throw new Error(error.response?.data?.message ?? "An unspecified error occurred. Please try again later.");
  }
};

const cleanLead = async (internal_id: string) => {
  const endpoint = `/collections/dev-clean`;

  try {
    const response = await axios.post(endpoint, {
      internal_id,
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

const submitOutcome = async (body: OutcomeBody) => {
    const endpoint = "/collections/outcome"
    // console.log("submitting outcome...", body);
  
    try {
      const res = await axios.post(endpoint, body);
      return res.data;  
    } catch (error) {
      throw error;
    }
};

const notifyManager = async ({ email }: { email: string }) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ message: "success" });
    }, 1000);
  });
};

// const getCollectionsHistory = async (agent_email: string, customerID: string): Promise<CollectionsHistory> => {};

export {
    getLead,
    unlockLead,
    submitOutcome,
    getOutcomeOptions,
    cleanLead,
    notifyManager,
}