import React, { useState } from 'react';
import {
  Button,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Modal, Loader } from '../../../GlobalComponents';
import TEAM_MEMBERS from "../../../Views/Admin/team_members.json";
import { unlockLead } from '../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { COLORS } from '../../../Theme';
import { useAuthSession } from '../../../Auth';
import {
  ToolContainer,
  HelperText,
  Content,
  Column,
  ActionsContainer,
} from "./styled-components";
import LeadTools from './LeadTools';


type DevToolsModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    lead?: Lead;
    methods: {
      onCopyCustomerId?: () => void;
      onUnlockCurrentLead?: () => void;
    }
};

export default function DevToolsModal({open, setOpen, lead, methods}: DevToolsModalProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [selectedTeamMember, setSelectedTeamMember] = useState<string | undefined>();
  const { email, onSelectProxyEmail } = useAuthSession();

  const { mutate: unlockLeadMutation, isPending: unlockLeadLoading } =
    useMutation({
      mutationFn: () => unlockLead(email, !lead ? "" : lead.profile.customerID),
      onSuccess: () => {
        window.alert("Lead unlocked successfully");
        navigate(`/`);
        queryClient.removeQueries({ queryKey: ["lead"] });
      },
    });

  const onSelectProxyHelper = (e: SelectChangeEvent) => {
    setSelectedTeamMember(e.target.value);
    //@ts-ignore
    onSelectProxyEmail(e.target.value);
    setOpen(false);
    navigate("/lead");
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      showXButton={false}
      width={lead ? "55vw" : "30vw"}
      height={"65vh"}
    >
      {unlockLeadLoading ? (
        <Loader />
      ) : (
        <Column>
          <Column style={{ marginBottom: 25 }}>
            <HelperText style={{ color: COLORS.PRIMARY, fontSize: 30 }}>
              Dev Tools
            </HelperText>
            <HelperText>Agent: {email}</HelperText>
          </Column>
          <Content>
            {lead && (
              <ToolContainer>
                <Column>
                  <HelperText>LEAD DETAILS</HelperText>
                  <div style={{ marginBottom: 20 }}>
                    <HelperText>
                      Customer ID: {lead.profile.customerID}
                    </HelperText>
                  </div>

                  <ActionsContainer>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                        marginBottom: 20,
                      }}
                    >
                      {methods?.onCopyCustomerId && (
                        <Button
                          variant="outlined"
                          onClick={methods.onCopyCustomerId}
                        >
                          Copy Customer ID
                        </Button>
                      )}
                      {lead && (
                        <Button
                          variant="outlined"
                          onClick={() => unlockLeadMutation()}
                        >
                          Unlock Current Lead
                        </Button>
                      )}
                    </div>
                  </ActionsContainer>
                </Column>
              </ToolContainer>
            )}
            <Column style={{ gap: 15 }}>
              <FormControl>
                <InputLabel id="select-proxy-team-member-label">
                  Select Proxy Team Member
                </InputLabel>
                <Select
                  labelId="select-proxy-team-member-label"
                  label="Select Proxy Team Member"
                  value={selectedTeamMember}
                  onChange={onSelectProxyHelper}
                  sx={{ width: 335 }}
                >
                  {TEAM_MEMBERS.map((teamMember: string) => (
                    <MenuItem key={teamMember} value={teamMember}>
                      {teamMember}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LeadTools setOpen={setOpen} />
            </Column>
          </Content>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{ marginTop: 2 }}
          >
            Close
          </Button>
        </Column>
      )}
    </Modal>
  );
}