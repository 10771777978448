import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getTeamMembers,
  getSavedSearches,
  getData,
  patchUpdateSavedSearch,
  getSearchAssignedTeamMembers,
} from "../api";
import TeamMembers from "./TeamMembers";
import styled from "@emotion/styled";
import Toolbar from "./Toolbar";
import SavedSearchPanel from "./SavedSearchPanel";
import { LoaderOverlay } from "../../../GlobalComponents";
import ConfirmModal from "./ConfirmModal";

export default function Assignees() {
  const [selectedSearch, setSelectedSearch] = useState<SavedSearch | null>(null);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<TeamMember[] | []>([]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { data, isLoading, refetch: refetchData } = useQuery({ queryKey: ["assignments-data"], queryFn: getData });

  useEffect(() => {
    console.log("data", data);
  }, [data]);
  
  const selectedSearchId = selectedSearch?.id ?? "";
  const { teamMembers, savedSearches } = data || {};

  const { data: searchAssignedTeamMembers, isLoading: searchAssignedTeamMembersLoading, refetch: refetchAssignedMembers } = useQuery({
    queryKey: ["search-assigned-team-members", selectedSearch?.id],
    queryFn: () => getSearchAssignedTeamMembers(selectedSearchId, teamMembers ?? []),
    enabled: !!selectedSearch,
  });

  useEffect(() => {
    setSelectedTeamMembers(searchAssignedTeamMembers || []);
  }, [searchAssignedTeamMembers]);

  useEffect(() => {
    console.log("searchAssignedTeamMembers", searchAssignedTeamMembers);
  },[searchAssignedTeamMembers]);

  const { mutate: saveChangesMutate, isPending: saveMutationPending } = useMutation({
    mutationFn: () => patchUpdateSavedSearch(selectedSearchId, selectedTeamMembers),
    // onMutate: async (search: SavedSearch, assignees: TeamMember[]) => {
    //   const previousSearch = search;
    //   search.assigned = assignees;
    //   return { previousSearch };
    // },
    onError: (error, variables, context) => {
      alert("Error updating saved search");
    },
    onSettled: (data, error, variables, context) => {
      setSuccessModalOpen(true);
      // refetchData();
    },
  });
  
  const onSearchClick = (search: SavedSearch) => {
    setSelectedSearch(search);
  };

  const onTeamMemberSelect = (teamMember: TeamMember) => {
    setSelectedTeamMembers((prev: TeamMember[]) => {
        const isAlreadySelected = prev.find((person) => person.email === teamMember.email);
        if (isAlreadySelected) {
            return prev.filter((person) => person.email !== teamMember.email);
        } else {
            return [...prev, teamMember];
        }
    });
  };

  const onSelectAll = () => {
    if(teamMembers) {
        const teamMembersIds = teamMembers.map((person) => person.id);
        const selectedTeamMembersIds = selectedTeamMembers.map((person) => person.id);
        const teamMembersNotSelected = teamMembersIds.filter((id) => !selectedTeamMembersIds.includes(id));
        if(teamMembersNotSelected.length === 0) {
            setSelectedTeamMembers([]);
            return;
        }
        setSelectedTeamMembers(teamMembers);
    }
  };

  const onReset = () => {
    setSelectedTeamMembers(searchAssignedTeamMembers || []);
  };
  
  return (
    <Container>
      <Toolbar savedSearches={savedSearches} isLoading={isLoading} onSearchClick={onSearchClick} />
      <TeamMembers
        teamMembers={teamMembers}
        isLoading={isLoading}
        onTeamMemberSelect={onTeamMemberSelect}
        selectedTeamMembers={selectedTeamMembers}
        selectEnabled={!!selectedSearch}
        onSelectAll={onSelectAll}
      />
      <SavedSearchPanel
        savedSearch={selectedSearch}
        assignees={selectedTeamMembers}
        onTeamMemberSelect={onTeamMemberSelect}
        onReset={onReset}
        onSave={() => saveChangesMutate()}
        isLoading={searchAssignedTeamMembersLoading}
        searchAssignedTeamMembers={searchAssignedTeamMembers}
      />
      <ConfirmModal 
        title="Success"
        description="Your changes have been saved."
        open={successModalOpen}
        setOpen={setSuccessModalOpen}
        onConfirm={() => {
          setSuccessModalOpen(false);
          refetchData();
          refetchAssignedMembers();
          // onReset();
        }}
        showCancel={false}
      />
      <LoaderOverlay isLoading={saveMutationPending} text="Saving Changes, please do not close this page"/>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0px 0px 10px #35466933;
  height: 80vh;
  overflow: hidden;
`;